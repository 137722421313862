import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Target, TargetStatus } from '../classes/target';
import { DocumentSurvey, DocumentTarget } from '../models/document.model';
import {
  CombinedResponseResults,
  EvaluateDAUPlanRequest,
  EvaluateManualPlanRequest,
  EvaluateMRFPlanRequest,
  EvaluateMultiMediaPlanDAUVehicleRequest,
  EvaluateMultiMediaPlanRequest,
  EvaluateMultiMediaPlanResponse,
  ManualInputVehicleRequest,
  ScheduleCombinedResponse,
  ScheduleEvaluationResponse,
} from '../models/engine.evaluate-multi-media-plan.models';
import {
  EvaluateMediaPlanRequest,
  EvaluateMediaPlanResponse,
  EvaluateMediaPlanResponseResults,
  EvaluateMediaPlanVehicleRequest,
} from '../models/engine.evaluate-mediaplan.models';
import {
  APIResponseTimer,
  TargetStatementWithId,
} from '../models/engine.models';
import {
  NaturalDeliveryGoalMetric,
  NaturalDeliveryRequest,
  NaturalDeliveryResponse,
  NaturalDeliveryResultResponse,
  NaturalDeliveryVehicle,
  SurveyInfo,
  TargetAudienceSetMultiSurveyRequest,
  TargetAudienceSetResponse,
  TargetAudienceSetVehicle,
  VehicleDefinition,
} from '../models/engine.media-evaluation.models';
import {
  MultiTargetEvaluationRequest,
  MultiTargetEvaluationResponse,
} from '../models/engine.target-evaluation.models';
import { ApiService } from './api.service';
import {
  SCH_DELIMITER,
  Schedule,
  TOTAL_SCHEDULE_NAME,
} from '../classes/schedule';
import { catchError } from 'rxjs/operators';
import {
  ScheduleTotalTag,
  stringToTag,
  tagToString,
} from '../classes/schedule-total';
import { ScheduleVehicle, TargetVehicle } from '../classes/vehicle';
import { CostMethod, Metrics, Result, ResultType } from '../classes/result';
import { VehicleGroup } from '../classes/vehicle-groups';
import { EffectiveReach } from '../classes/media-planner';
import { SpotplanSchedule } from '../classes/spotplan-schedule';
import {
  DaypartResult,
  EMPTY_DAYPART_RESULTS,
} from '../classes/daypart-result';
import {
  CreateMrfFileRequest,
  CreateMrfFileResponse,
  MrfCampaignInfo,
  MrfFileVehicleRequest,
  SurveyCompatibiltyResponse,
} from '../models/multi-survey-mrf.models';
import { FileType } from '../models/multi-survey.models';
import { MultiSurvey } from '../classes/multi-survey';
import { Operator } from '../models/visual-code-builder.models';
import { Auth } from 'aws-amplify';
import { WebsocketService } from './websocket.service';
import { CONNECTED, CONNECTION_CLOSED } from '../models/optimise.model';
import uniqid from 'uniqid';

const EMPTY_EVALUATION_RESPONSE: MultiTargetEvaluationResponse = {
  status: { hits: 0, statusCode: 0, success: false, took: '' },
  populations: [0],
  samples: [0],
};

@Injectable({
  providedIn: 'root',
})
export class EngineService {
  HARDCODED_WEIGHT_TO_ONE: number = 1;
  lastWarmupTimeTargetEvaluation: number = Date.now() - 1000000;
  lastWarmupTimeCmpEngine: number = Date.now() - 1000000;
  lastWarmupTimeTargetCoding: number = Date.now() - 1000000;
  constructor(
    private apiService: ApiService,
    private wsService: WebsocketService
  ) {}

  // build an evaluation request object from the passed in vehicles and schedules
  private vehiclesSchedule(
    targets: Target[],
    schedule: Schedule,
    vehicleGroups: VehicleGroup[],
    vehicles: ScheduleVehicle[] = null,
    multiSurveyManualInput: ManualInputVehicleRequest = null,
    includeUniqueReach: boolean = false
  ) {
    const buyingTargetDefinitions: TargetStatementWithId[] = [];
    const vehiclesReq: EvaluateMediaPlanVehicleRequest[] = [];
    const manualVehiclesReq: ManualInputVehicleRequest[] = [];
    let hasCosts = false;
    const exclusiveRchGroups: string[] = [];
    const exclusiveRchVehicleIds: string[] = [];

    const combiningSchedules = schedule.name === TOTAL_SCHEDULE_NAME;
    const target = targets[0];

    const evaluateVehs =
      vehicles || schedule.vehicles.filter((v) => v.targetId === target.id);

    evaluateVehs.forEach((vehicle) => {
      // in case any vehicles are from the combined schedule
      const [mediaVehicleId, sIndex] = vehicle.vehicleId.split(SCH_DELIMITER);

      const mediaVehicle = target.vehicle(mediaVehicleId); //vehicle.vehicleId
      const isAddressable = !!mediaVehicle.addressableConfig;
      const isDirectMail = !!mediaVehicle.addressableConfig?.isDirectMail;
      let id = vehicle.vehicleId;
      let mnemonic = mediaVehicle.mnemonic;

      // any inserts or impressions
      if (vehicle.result.anyInput() && !mediaVehicle.isMultiSurvey) {
        // NON BROADCAST processing (spots stored in separate spotplan objects)
        if (vehicle.result.type !== ResultType.broadcast) {
          let buyingTargetId = undefined; // assigned the buying target if this is an addressable media

          // grab the addressable target and build the definition for it
          if (mediaVehicle.addressableConfig) {
            // check if already in the request definition
            const exists = buyingTargetDefinitions.find(
              (buyingTarget) =>
                buyingTarget.targetId ===
                mediaVehicle.addressableConfig.targetId
            );

            exists ? (buyingTargetId = exists.id) : null;

            if (!exists) {
              const addrTarget = targets.find(
                (target) =>
                  target.id === mediaVehicle.addressableConfig.targetId
              );

              buyingTargetId = buyingTargetDefinitions.length;

              buyingTargetDefinitions.push({
                id: buyingTargetId,
                targetId: mediaVehicle.addressableConfig.targetId,
                code: addrTarget.documentTarget.jsonCoding,
              });
            }
          }

          const groups = [
            `${tagToString(ScheduleTotalTag.mediatype)}|${
              mediaVehicle.mediaType
            }`,
          ];
          exclusiveRchGroups.push(groups[groups.length - 1]);
          exclusiveRchVehicleIds.push(id);

          // create a group to combine vehicles across multiple schedules
          if (combiningSchedules) {
            groups.push(
              `${tagToString(ScheduleTotalTag.vehicle)}|${
                mediaVehicle.mnemonic
              }`
            );
          }
          const vehResult = vehicle.result;
          const numberOfInserts = isDirectMail
            ? vehResult.insertsDM
            : isAddressable
            ? 0
            : vehicle.result.inserts;
          const impressionsBought =
            isAddressable && !isDirectMail
              ? vehicle.result.buyingImpressions
              : undefined;
          const duration =
            (isDirectMail ? vehResult.durationDM : vehResult.duration) || 1;
          const frequencyCap =
            vehResult.freqCapping === 0 ? undefined : vehResult.freqCapping;

          // maybe just id as the schedule has already been added?
          vehiclesReq.push({
            id,
            mnemonic,
            title: mediaVehicle.title,
            buyingTargetId,
            numberOfInserts,
            impressionsBought,
            duration,
            frequencyCap,
            groups,
            vehicleResultsRequired:
              vehResult.dirty || includeUniqueReach || combiningSchedules,
            surveyCode: mediaVehicle.survey.code,
          });

          // at least one vehicle has costs
          hasCosts =
            this.getVehicleCostForEvaluation(
              vehiclesReq[vehiclesReq.length - 1],
              vehicle.result,
              isAddressable
            ) || hasCosts;

          // add any vehicle groups to the request object
          const gr: string[] = this.getGroupsForEvaluation(
            vehicleGroups,
            vehicle.vehicleId
          );
          if (gr.length) {
            vehiclesReq[vehiclesReq.length - 1].groups.push(...gr);
            exclusiveRchGroups.push(...gr);
          }
        }

        //BROADCAST PROCESSING - fetch spots from the spotplan object
        else {
          const spotplan = schedule.spotplans.findSpotplan(vehicle.vehicleId);
          const groups = [
            `${tagToString(ScheduleTotalTag.mediatype)}|${
              mediaVehicle.mediaType
            }`,
            `broadcast|${mediaVehicle.id}`,
          ];
          const broadCastExclusiveReachGroups = [
            `${tagToString(ScheduleTotalTag.mediatype)}|${
              mediaVehicle.mediaType
            }`,
          ];
          exclusiveRchGroups.push(
            broadCastExclusiveReachGroups[
              broadCastExclusiveReachGroups.length - 1
            ]
          );
          exclusiveRchGroups.push(`broadcast|${vehicle.vehicleId}`);

          // create a group to combine broadcast dayparts across multiple schedules
          if (combiningSchedules) {
            groups.push(
              `${tagToString(ScheduleTotalTag.vehicle)}|${
                mediaVehicle.mnemonic
              }`
            );
          }

          const daypartZeroList: DaypartResult[] = [];
          spotplan.dayparts.forEach((daypart) => {
            // sum spots across all weeks for the daypart

            // iterate through all weeks adding dayparts from each week and mark the week for collecting resuts later
            for (let week = 0; week < spotplan.weekCount; week++) {
              const dpResult = daypart.result.result(week, target.id);
              if (dpResult && dpResult.inserts > 0) {
                const vehReq: EvaluateMediaPlanVehicleRequest = {
                  id: `${daypart.id}|${vehicle.vehicleId}|${week}`,
                  mnemonic: daypart.mnemonic,
                  title: daypart.title,
                  numberOfInserts: dpResult.inserts,
                  duration: 1,
                  groups: [
                    ...groups,
                    `broadcast|${mediaVehicle.id}|${week}`,
                    `broadcast|${mediaVehicle.id}|${week}|${daypart.startDay}`,
                    `broadcast|${mediaVehicle.id}|-1|${daypart.startDay}`,
                  ],
                  vehicleResultsRequired: dpResult.dirty,
                  surveyCode: mediaVehicle.survey.code,
                };

                // at least one daypart has costs
                hasCosts =
                  this.getVehicleCostForEvaluation(vehReq, dpResult) ||
                  hasCosts;
                vehiclesReq.push(vehReq);
              } else {
                dpResult ? daypartZeroList.push(dpResult) : null;
              }
            }
          }); // spotplan.dayparts
          spotplan.dayparts[0].result.zeroResults(daypartZeroList);
        }
      } else {
        if (mediaVehicle.isMultiSurvey) {
          const multiSurveyConfig = mediaVehicle.multiSurveyConfig;
          if (multiSurveyConfig && multiSurveyConfig.manualInput) {
            let reach: number;
            let grp: number;
            let frequency: number;
            let inserts: number;
            let cost: number;

            // new manual input added or existing one is being edited
            if (
              multiSurveyManualInput &&
              multiSurveyManualInput.manualEntryId === vehicle.vehicleId
            ) {
              reach = multiSurveyManualInput.reach;
              grp = multiSurveyManualInput.grp;
              frequency = multiSurveyManualInput.frequency;
              inserts = multiSurveyManualInput.numberOfSpots;
              cost = multiSurveyManualInput.cost;
            } else {
              // already existing manual entries
              reach = vehicle.result.reachPct;
              grp = vehicle.result.grps;
              frequency = vehicle.result.avgFrequency;
              inserts = vehicle.result.inserts;
              cost = vehicle.result.totalCost;
            }
            const validData = (reachVal, grpVal, freqVal): boolean => {
              return (
                !!(reachVal && grpVal) ||
                (reachVal && freqVal) ||
                (grpVal && freqVal)
              );
            };

            if (validData(reach, grp, frequency)) {
              manualVehiclesReq.push({
                reach,
                frequency,
                numberOfSpots: inserts,
                cost,
                manualEntryId: vehicle.vehicleId,
              });
            }
            if (cost) hasCosts = true;
          }
        }
        if (!mediaVehicle.isMultiSurvey) {
          targets.forEach((target) => {
            const targetVehicles = schedule.vehicles.filter(
              (v) =>
                v.targetId === target.id && vehicle.vehicleId === v.vehicleId
            );
            targetVehicles.forEach((vehicle) => {
              if (!vehicle.result.anyInput()) vehicle.result.zeroResults();
            });
          });
        }
      }
    });

    return {
      buyingTargetDefinitions,
      vehiclesReq,
      manualVehiclesReq,
      hasCosts,
      exclusiveRchGroups: Array.from(new Set<string>(exclusiveRchGroups)),
      exclusiveRchVehicledIds: Array.from(
        new Set<string>(exclusiveRchVehicleIds)
      ),
    };
  }

  // extract and process costs for the given vehicle for the evaluation
  private getVehicleCostForEvaluation(
    request: EvaluateMediaPlanVehicleRequest,
    vehicleResult: Result | DaypartResult,
    addressableConfig: boolean = false
  ): boolean {
    let cpm = vehicleResult.cpm;
    let cpp = vehicleResult.cpp;
    let costTargetId = vehicleResult.costTargetId;
    let hasCosts = false;

    // do something if it's addressable
    if (addressableConfig) {
      cpm = (vehicleResult as Result).buyingCpm;
      cpp = (vehicleResult as Result).buyingCpp;
      costTargetId = undefined; //if addressable, buyingTargetId is used for the cost target
    }

    switch (vehicleResult.costMethod) {
      case CostMethod.unitCost:
        request['costPerInsertion'] = vehicleResult.unitCost;
        request['costTargetId'] = costTargetId;
        hasCosts = true;
        break;

      case CostMethod.cpm:
      case CostMethod.baseCpm:
        request['costPerThousand'] = cpm;
        request['costTargetId'] = costTargetId;
        hasCosts = true;
        break;

      case CostMethod.cpp:
        request['costPerPoint'] = cpp;
        request['costTargetId'] = costTargetId;
        hasCosts = true;
        break;
    }

    return hasCosts;
  }

  /**
   * Provide reach and Frequency results on the vehicle groups and targets supplied.  This creates multiple requests, one for each schedule
   * Will call evaluateMediaPlan multiple times with each single schedule
   *
   * @param {Target[]} targets  Array of targets to evaluate
   * @param {Schedule[]} schedule object holding all vehicles and inserts to evaluate
   * @param {VehicleGroup[]} vehicles array containing a subset of vehicles for evaluation. optional
   * @param {EffectiveReach} effectiveReachInput effective from/to definition
   * @param {number} frequencyLevel freq level to request
   * @returns results via populating the schedule object itself
   */

  evaluateMediaPlanAllSchedules(
    targets: Target[],
    allSchedules: Schedule[],
    vehicleGroups: VehicleGroup[],
    includeUniqueReach: boolean,
    vehicles: ScheduleVehicle[] = null,
    effectiveReachInput: EffectiveReach = null,
    frequencyLevel?: number,
    multiSurveyData?: MultiSurvey[],
    multiSurveyManualInput?: ManualInputVehicleRequest,
    surveyList?: SurveyInfo[]
  ): Observable<boolean[]> {
    const reqs = allSchedules.map((schedule) =>
      this.evaluateMediaPlan(
        targets,
        schedule,
        vehicleGroups,
        includeUniqueReach,
        vehicles,
        effectiveReachInput,
        frequencyLevel,
        multiSurveyData,
        multiSurveyManualInput,
        surveyList
      )
    );
    return forkJoin(reqs);
  }

  /**
   * Provide reach and Frequency results on the vehicle groups and targets supplied
   *
   * @param {Target[]} targets  Array of targets to evaluate
   * @param {Schedule[]} schedule object holding all vehicles and inserts to evaluate
   * @param {VehicleGroup[]} vehicles array containing a subset of vehicles for evaluation. optional
   * @param {EffectiveReach} effectiveReachInput effective from/to definition
   * @param {number} frequencyLevel freq level to request
   * @returns results via populating the schedule object itself
   */
  evaluateMediaPlan(
    targets: Target[],
    schedule: Schedule,
    vehicleGroups: VehicleGroup[],
    includeUniqueReach: boolean,
    vehicles: ScheduleVehicle[] = null,
    effectiveReachInput: EffectiveReach = null,
    frequencyLevel?: number,
    multiSurveyData?: MultiSurvey[],
    multiSurveyManualInput?: ManualInputVehicleRequest,
    surveyList?: SurveyInfo[]
  ): Observable<boolean> {
    if (!targets || !targets.length) return of(false);
    return new Observable((observable) => {
      const vehiclesScheduleResult = this.vehiclesSchedule(
        targets,
        schedule,
        vehicleGroups,
        vehicles,
        multiSurveyManualInput,
        includeUniqueReach
      );

      // no vehicles to process so clear results shortcut
      if (vehiclesScheduleResult.vehiclesReq.length === 0) {
        this.clearMediaplan(schedule, targets);
        schedule.spotplans.clearAll();
        schedule.totalsRecalcRequired = false;
        if (
          (multiSurveyData === undefined || multiSurveyData.length === 0) &&
          vehiclesScheduleResult.manualVehiclesReq.length === 0
        ) {
          observable.next(true);
          observable.complete();
          return;
        }
      }

      const planningTargets = targets.filter((target) => target.planningTarget);

      // get any missing json coding objects
      this.getTargetCoding(
        targets
          .filter((tgt) => tgt.status !== TargetStatus.disabled)
          .map((target) => target.documentTarget)
      ).subscribe((success) => {
        if (!success) {
          observable.next(false);
          observable.complete();
          return;
        }
        const targetDefinitions: TargetStatementWithId[] = targets
          .filter((t) => t.planningTarget)
          .map((target, id) => {
            return {
              id,
              code: target.documentTarget.jsonCoding,
              targetId: target.id,
              customPopulation: target.documentTarget.customPopulation,
            };
          });

        // now we have planningTarget and buyingTarget definitions, reassign the  cost targetIds
        vehiclesScheduleResult.vehiclesReq.forEach((req) => {
          // a cost (cpm or cpp) definition - re-assign to the id from the buying Target definitions
          if (req.costTargetId) {
            const planningTarget = targetDefinitions.find(
              (tgt) => tgt.targetId === req.costTargetId
            );
            req.costTargetId = planningTarget.id;
          }
        });

        const multiMediaRequestOptions: EvaluateMultiMediaPlanRequest =
          this.buildMultiMediaRequestOptions(
            targets,
            schedule,
            vehiclesScheduleResult,
            targetDefinitions,
            includeUniqueReach,
            multiSurveyData,
            effectiveReachInput,
            frequencyLevel,
            surveyList
          );

        const apiServiceRequest = this.apiService.request(
          'POST',
          environment.api.cmp.url,
          environment.api.cmp.endPoint.evaluateMultiSurveyPlan,
          {
            body: multiMediaRequestOptions,
          }
        );

        this.lastWarmupTimeCmpEngine = Date.now();
        apiServiceRequest
          .pipe(
            // any errors are reported by the api Service, so not just return success false for component cleanup
            catchError((err) => {
              return of({ success: false });
            })
          )
          .subscribe((data) => {
            // data: EvaluateMultiMediaPlanResponse | EvaluateMediaPlanResponse
            if (data.success) {
              this.populateScheduleWithMultiMediaData(
                data,
                planningTargets,
                vehiclesScheduleResult.exclusiveRchGroups,
                schedule
              );
            }

            if (schedule.name === TOTAL_SCHEDULE_NAME)
              this.populateGroupInsertsFromVehicles(schedule);

            schedule.totalsRecalcRequired = false;
            observable.next(data.success);
            observable.complete();
          });
      });
    });
  }

  evaluateSingleBroadcastMediaplan(
    targets: Target[],
    vehicle: TargetVehicle,
    schedule: Schedule,
    spotplan: SpotplanSchedule,
    weeksToProcess: number[]
  ): Observable<EvaluateMediaPlanResponse> {
    return new Observable((observable) => {
      const planningTargets = targets.filter((target) => target.planningTarget);
      const target = targets[0];
      const vehicles: EvaluateMediaPlanVehicleRequest[] = [];

      // build vehicle request
      // if all weeks requested (week===-1), capture dayparts across each week

      const nonEmptyWeeks: Set<number> = new Set<number>();

      planningTargets.forEach((target) => {
        for (let w = 0; w <= spotplan.weekCount - 1; w++) {
          spotplan.dayparts.forEach((daypart) => {
            const res = daypart.result.result(w, target.id);
            if (res && res.inserts > 0) {
              nonEmptyWeeks.add(w);
              const vehReq: EvaluateMediaPlanVehicleRequest = {
                id: `${daypart.id}|${vehicle.id}|${w}`,
                mnemonic: daypart.mnemonic,
                title: daypart.title,
                numberOfInserts: res.inserts,
                duration: res.duration || 1,
                groups: [
                  `broadcast|${vehicle.id}|${w}`,
                  `broadcast|${vehicle.id}|${w}|${daypart.startDay}`,
                  `broadcast|${vehicle.id}|-1|${daypart.startDay}`,
                ],
                vehicleResultsRequired: res.dirty,
                surveyCode: vehicle.survey.code,
                // costPerPoint: res.cpp,
                // costPerThousand: res.cpm,
                // costTargetId: 0,
              };
              vehicles.push(vehReq);
            }
          });
        }
      });

      // either no vehicles or at least 1 of the weeks does not have spots,
      // so zero the week totals and main broadcast total as needed
      if (vehicles.length === 0 || nonEmptyWeeks.size !== spotplan.weekCount) {
        targets.forEach((target) => {
          vehicles.length === 0
            ? schedule.vehicle(target, vehicle.id).result.zeroResults()
            : null;

          for (let w = 0; w <= spotplan.weekCount - 1; w++) {
            !nonEmptyWeeks.has(w)
              ? spotplan.setWeekTotal(w, target.id, EMPTY_DAYPART_RESULTS)
              : null;
          }
        });
      }

      // if no vehicles then no need to continue
      if (vehicles.length === 0) {
        observable.next({ success: true, results: [] });
        observable.complete();
      } else {
        // target definitions
        const targetDefinitions: TargetStatementWithId[] = planningTargets.map(
          (target, id) => {
            return {
              id,
              code: target.documentTarget.jsonCoding,
              targetId: target.id,
              customPopulation: target.documentTarget.customPopulation,
            };
          }
        );

        const options: EvaluateMediaPlanRequest = {
          surveyCode: target.survey.code,
          targetDefinitions,
          weightSet: this.HARDCODED_WEIGHT_TO_ONE,
          authorizationGroup: target.survey.authorizationGroup,
          vehicles,
          frequencyLevel: 0,
          outputSchedule: ['Distribution', 'Impressions'],
          outputGroup: ['Distribution', 'Impressions'],
          outputVehicle: ['Distribution', 'Impressions'],
          reportExclusiveReachPerVehicle: [vehicle.id],
          reportExclusiveReachForGroup: [],
          engineMode: 'sync',
        };

        this.apiService
          .request(
            'POST',
            environment.api.cmp.url,
            environment.api.cmp.endPoint.evaluateMediaPlan,
            { body: options }
          )
          .pipe(
            // any errors are reported by the api Service, so not just return success false for component cleanup
            catchError((err) => {
              return of({ success: false });
            })
          )
          .subscribe((data: EvaluateMediaPlanResponse) => {
            if (data.success) {
              this.populateScheduleWithData(
                data.results,
                targets,
                [],
                schedule,
                true
              );
            }

            observable.next(data);
            observable.complete();
          });
      } // vehicles empty
    });
  }

  private buildMultiMediaRequestOptions(
    targets: Target[],
    schedule: Schedule,
    vehiclesScheduleResult,
    targetDefinitions: TargetStatementWithId[],
    includeUniqueReach: boolean,
    multiSurveyData: MultiSurvey[] = null,
    effectiveReachInput: EffectiveReach = null,
    frequencyLevel?: number,
    surveyList?: SurveyInfo[]
  ): EvaluateMultiMediaPlanRequest {
    // request cost calcs from the engine if any are in the vehicle request

    const combiningSchedules = schedule.name === TOTAL_SCHEDULE_NAME;
    const costOptions = [
      'Cost',
      'CostPerThousand',
      'CostPerThousandBuyingTarget',
      'CostPerPoint',
      'CostPerPointBuyingTarget',
      'CostPerThousandBaseTarget',
    ];
    const costRequestOptions = vehiclesScheduleResult.hasCosts
      ? costOptions
      : [];

    const effectiveReachRequestOptions =
      effectiveReachInput && effectiveReachInput.active
        ? ['EffectiveReach', 'EffectiveReachPercentage']
        : [];

    // distribution columns needed for freq. distr. view (frequencyLevel only sent in this case)
    const distributionRequestOptions =
      frequencyLevel > 0
        ? [
            'DistributionPercentage',
            'DistributionCumulative',
            'DistributionCumulativePercentage',
            'DistributionImpressions',
          ]
        : [];

    const buyingTargetDefinitions: TargetStatementWithId[] = [
      ...vehiclesScheduleResult.buyingTargetDefinitions,
    ];
    const vehiclesReq: EvaluateMediaPlanVehicleRequest[] = [
      ...vehiclesScheduleResult.vehiclesReq,
    ];
    const baseTargetDefinition: TargetStatementWithId = {
      id: 0,
      code: { Val: 1 },
    };

    const manualVehiclesReq: ManualInputVehicleRequest[] = [
      ...vehiclesScheduleResult.manualVehiclesReq,
    ];

    const target = targets[0];

    // ESG Provider and request option array
    const ESGProvider = target.survey.esgProviders?.length
      ? target.survey.esgProviders[0]
      : '';
    const esgRequestOptions = ESGProvider ? ['ESG', 'ESGStability'] : [];

    let effectiveDeliveryOptions: any = {};
    if (effectiveReachInput && effectiveReachInput.active) {
      effectiveDeliveryOptions = {
        effectiveDeliveryLevelMin: effectiveReachInput.effectiveReachFrom,
        effectiveDeliveryLevelMax: effectiveReachInput.effectiveReachTo,
      };
    }

    const outputSchedule = [
      'Distribution',
      'Impressions',
      'Reach',
      'ReachPercentage',
      ...distributionRequestOptions,
      ...effectiveReachRequestOptions,
      ...esgRequestOptions,
      ...costRequestOptions,
    ];

    const outputGroup = [
      'Distribution',
      'Impressions',
      'Reach',
      'ReachPercentage',
      ...distributionRequestOptions,
      ...effectiveReachRequestOptions,
      ...esgRequestOptions,
      ...costRequestOptions,
    ];

    const outputVehicle = [
      'Distribution',
      'Impressions',
      'Reach',
      'ReachPercentage',
      ...effectiveReachRequestOptions,
      ...esgRequestOptions,
      ...costRequestOptions,
    ];

    const mediaPlanOptions: EvaluateMediaPlanRequest = {
      surveyCode: target.survey.code,
      scheduleId: schedule.id,
      authorizationGroup: target.survey.authorizationGroup,
      targetDefinitions,
      buyingTargetDefinitions,
      baseTargetDefinition,
      weightSet: this.HARDCODED_WEIGHT_TO_ONE,
      vehicles: vehiclesReq,
      ESGProvider,
      ...effectiveDeliveryOptions,
      frequencyLevel: frequencyLevel || 0,
      outputSchedule,
      outputGroup,
      outputVehicle,
      reportExclusiveReachForVehicle: includeUniqueReach
        ? vehiclesScheduleResult.exclusiveRchVehicledIds
        : [],
      reportExclusiveReachForGroup: includeUniqueReach
        ? vehiclesScheduleResult.exclusiveRchGroups
        : [],
      surveyList: surveyList?.length
        ? surveyList
        : [
            {
              surveyCode: target.survey.code,
              authorizationGroup: target.survey.authorizationGroup,
            },
          ],
    };

    const DAUPlans: EvaluateDAUPlanRequest[] = this.buildDAURequestOptions(
      targets,
      multiSurveyData,
      outputSchedule,
      outputVehicle,
      costOptions
    );
    const DAUHasCosts = (DAUPlansData) => {
      let hasCosts = false;
      DAUPlansData.forEach((dauPlan) => {
        if (
          dauPlan.outputVehicle.find((outputOpt) =>
            costOptions.includes(outputOpt)
          )
        )
          hasCosts = true;
      });
      return hasCosts;
    };

    const manualPlans: EvaluateManualPlanRequest[] =
      this.buildManualInputRequestOptions(
        targets,
        manualVehiclesReq,
        outputSchedule,
        combiningSchedules
      );

    const mrfPlans: EvaluateMRFPlanRequest[] = this.buildMRFRequestOptions(
      multiSurveyData,
      outputSchedule,
      outputGroup,
      outputVehicle,
      costOptions,
      combiningSchedules
    );

    const MRFHasCosts =
      mrfPlans.find((mrfPlan) => !!mrfPlan.media.cost) !== undefined;

    const DAUCosts = DAUHasCosts(DAUPlans);
    const outputCombinedSchedule =
      !vehiclesScheduleResult.hasCosts && (DAUCosts || MRFHasCosts)
        ? [...outputSchedule, ...costOptions]
        : outputSchedule;

    const outputVehicleGlobal =
      !vehiclesScheduleResult.hasCosts && (DAUCosts || MRFHasCosts)
        ? [...mediaPlanOptions.outputVehicle, ...costOptions]
        : mediaPlanOptions.outputVehicle;

    const outputGroupGlobal =
      !vehiclesScheduleResult.hasCosts && (DAUCosts || MRFHasCosts)
        ? [...mediaPlanOptions.outputGroup, ...costOptions]
        : mediaPlanOptions.outputGroup;

    return {
      surveyCode: target.survey.code,
      authorizationGroup: mediaPlanOptions.authorizationGroup,
      targetDefinitions: mediaPlanOptions.targetDefinitions,
      frequencyLevel: mediaPlanOptions.frequencyLevel,
      ...effectiveDeliveryOptions,
      mediaPlan: vehiclesReq.length ? mediaPlanOptions : {},
      DAUPlans,
      manualPlans,
      mrfPlans,
      outputCombinedSchedule,
      outputVehicle: outputVehicleGlobal,
      outputGroup: outputGroupGlobal,
      outputSchedule: outputCombinedSchedule,
      engineMode: 'sync',
    };
  }

  private buildMRFRequestOptions(
    multiSurveyData: MultiSurvey[],
    outputSchedule: string[],
    outputGroup: string[],
    outputVehicle: string[],
    costOptions: string[],
    combiningSchedules: boolean
  ): EvaluateMRFPlanRequest[] {
    const requests: EvaluateMRFPlanRequest[] = [];
    if (!multiSurveyData) return requests;

    const mrfFiles = multiSurveyData.filter(
      (mrf) => mrf.fileType === FileType.MRF
    );

    mrfFiles.forEach((mrfFile) => {
      const mrfData = mrfFile.mrf.fileData;
      const hasCost: boolean = !!mrfData.media.cost;
      // add cost outputOptions if not already there
      if (
        hasCost &&
        !costOptions.find((costOpt) => outputVehicle.includes(costOpt))
      ) {
        outputVehicle = [...outputVehicle, ...costOptions];
        outputSchedule = [...outputSchedule, ...costOptions];
        outputGroup = [...outputGroup, ...costOptions];
      }

      requests.push({
        scheduleId: mrfFile.id,
        keepThousands: mrfFile.keepThousands,
        outputSchedule,
        outputVehicle,
        outputGroup,
        header: mrfData.header,
        market: mrfData.market,
        universalDemos: mrfData.universalDemos,
        cells: mrfData.cells,
        targets: mrfData.targets,
        media: mrfData.media,
      });
    });

    return requests;
  }

  private buildDAURequestOptions(
    targets: Target[],
    multiSurveyData: MultiSurvey[],
    outputSchedule: string[],
    outputVehicle: string[],
    costOptions: string[]
  ): EvaluateDAUPlanRequest[] {
    const fileOptions: EvaluateDAUPlanRequest[] = [];
    if (multiSurveyData) {
      const target =
        targets.find((tgt) =>
          [TargetStatus.active, TargetStatus.noChange].includes(tgt.status)
        ) || targets[0];
      const targetId = targets.findIndex((tgt) => tgt.id === target.id);

      multiSurveyData.forEach((multiSurvey) => {
        if (
          multiSurvey.fileType === FileType.DAU &&
          multiSurvey.vehicles.length
        ) {
          let hasCost: boolean = false;
          const DAUVehiclesReqs: EvaluateMultiMediaPlanDAUVehicleRequest[] = [];
          let mediaType = null;
          multiSurvey.vehicles.forEach((dauVeh) => {
            const targetVeh = target.vehicle(dauVeh.code);

            if (!mediaType) mediaType = target.vehicle(dauVeh.code).mediaType;
            DAUVehiclesReqs.push({
              id: dauVeh.code,
              name: dauVeh.title,
              numberOfInserts: dauVeh.result.inserts,
              coverage: dauVeh.audience,
              selfPair: dauVeh.selfPair || 0,
              costPerInsertion: dauVeh.result.unitCost,
            });
            if (dauVeh.result.unitCost) hasCost = true;
          });

          if (
            hasCost &&
            !costOptions.find((costOpt) => outputVehicle.includes(costOpt))
          ) {
            outputVehicle = [...outputVehicle, ...costOptions];
            outputSchedule = [...outputSchedule, ...costOptions];
          }

          if (DAUVehiclesReqs.length) {
            fileOptions.push({
              keepThousands: multiSurvey.keepThousands,
              population:
                multiSurvey.targets[0].population *
                multiSurvey.targets[0].scale,
              scheduleId: mediaType,
              targetId,
              distribution: multiSurvey.distribution,
              vehicles: DAUVehiclesReqs,
              outputVehicle,
              outputSchedule,
            });
          }
        }
      });
    }

    return fileOptions;
  }

  private buildManualInputRequestOptions(
    targets: Target[],
    multiSurveyManualVehicles: ManualInputVehicleRequest[],
    outputSchedule: string[],
    combiningSchedules: boolean
  ) {
    const manualScheduleOptions: EvaluateManualPlanRequest[] = [];
    const targetId = targets.findIndex((tgt) =>
      [TargetStatus.active, TargetStatus.noChange].includes(tgt.status)
    );

    if (multiSurveyManualVehicles.length) {
      multiSurveyManualVehicles.forEach((manualVeh) => {
        manualScheduleOptions.push({
          scheduleId: manualVeh.manualEntryId,
          targetId,
          reach: manualVeh.reach,
          GRPs: manualVeh.grp,
          frequency: manualVeh.frequency,
          outputSchedule,
        });

        const manualVehicle =
          manualScheduleOptions[manualScheduleOptions.length - 1];
        if (manualVeh.cost) {
          manualVehicle.cost = manualVeh.cost;
        }

        if (manualVeh.numberOfSpots) {
          manualVehicle.numberOfSpots = manualVeh.numberOfSpots;
        }
      });
    }

    return manualScheduleOptions;
  }

  private populateGroupInsertsFromVehicles(totSchedule: Schedule) {
    // first, populate the groups based on combined schedule vehicles
    totSchedule.scheduleTotal.forEach((total) => {
      const veh = totSchedule.vehicles.find((v) => v.vehicleId === total.group);
      if (veh) total.result.addResults({ inserts: veh.result.inserts });
    });
  }

  // TEMPORARY TEST FOR new evaluateMultiMediaPlan response
  private populateScheduleWithMultiMediaData(
    data: EvaluateMultiMediaPlanResponse,
    targets: Target[],
    exclusiveRchGroups: string[],
    currentSchedule: Schedule,
    singleBroadcastProcessing: boolean = false // called from 'evaluateSingleBroadcastMediaplan()' only)
  ) {
    // consume regular mediaplan results
    data.results.mediaPlanResults.forEach((scheduleResult) => {
      this.populateScheduleWithData(
        scheduleResult.results,
        targets,
        exclusiveRchGroups,
        currentSchedule,
        singleBroadcastProcessing
      );
    });

    // consume DAU results
    this.parseDAUResults(data.results.DAUResults, targets, currentSchedule);

    // consume MRF results
    this.parseMrfResults(data.results.mrfResults, targets, currentSchedule);

    // consume Manual results
    this.parseManualResults(
      data.results.manualResults,
      targets,
      currentSchedule
    );

    // schedule combined total
    this.parseCombinedResults(
      data.results.combinedResults,
      targets,
      currentSchedule
    );
  }

  private parseDAUResults(
    scheduleResults: ScheduleEvaluationResponse[],
    targets: Target[],
    currentSchedule: Schedule
  ) {
    scheduleResults.forEach((scheduleResult) => {
      scheduleResult.results.forEach((DAUResult) => {
        const universe = DAUResult.universe;
        const target = targets[DAUResult.targetId];
        DAUResult.distributionPerVehicle.forEach((veh) => {
          // possible DAU reach to extract
          let customReach000 = undefined;
          let reach = this.calculateReach(
            veh.distribution.distribution,
            universe
          );
          reach = reach < 0.000001 ? 0 : reach;

          // load reach directly if available
          if (veh.distribution.reachPercentage) {
            reach = veh.distribution.reachPercentage / 100;
            customReach000 = veh.distribution.reach;
          }

          const vehicle = currentSchedule.vehicle(target, veh.id);
          vehicle.result.addResults({
            population: universe,
            reach: reach || vehicle.result.reach,
            customReach000,
            effectiveReach: (veh.distribution.effectiveReach += 0),
            effectiveReachPct: (veh.distribution.effectiveReachPercentage += 0),
            impressions: veh.distribution.impressions,
            esgScore: veh.distribution.ESG || -1,
            esgStability: veh.distribution.ESGStability || -1,
            totalCost: veh.distribution.cost,
            cpp: veh.distribution.costPerPoint,
            cpm: veh.distribution.costPerThousand,
            buyingCpm: veh.distribution.costPerThousandBuyingTarget,
            buyingCpp: veh.distribution.costPerPointBuyingTarget,
            ...(veh.exclusiveReach
              ? { uniqueReach000: veh.exclusiveReach }
              : undefined),
          });
        }); // distributionPerVehicle end

        // media type totals
        const schedByTarget = currentSchedule.getScheduleTotal(
          scheduleResult.scheduleId,
          ScheduleTotalTag.multiSurvey,
          target
        );

        // extract reach results passed directly or calcuate from distribution array
        let customReach000 = undefined;
        let reach = this.calculateReach(
          DAUResult.distributionPerSchedule.distribution,
          universe
        );

        // precalcauted reaches
        if (DAUResult.distributionPerSchedule.reachPercentage) {
          reach = DAUResult.distributionPerSchedule.reachPercentage / 100;
          customReach000 = DAUResult.distributionPerSchedule.reach;
        }

        schedByTarget.result.addResults({
          reach,
          customReach000,
          population: universe,
          impressions: DAUResult.distributionPerSchedule.impressions,
          totalCost: DAUResult.distributionPerSchedule.cost,
          cpp: DAUResult.distributionPerSchedule.costPerPoint,
          cpm: DAUResult.distributionPerSchedule.costPerThousand,
          reachedExactly: DAUResult.distributionPerSchedule.distribution,
          reachedExactlyPct:
            DAUResult.distributionPerSchedule.distributionPercentage,
          reachedAtLeast000:
            DAUResult.distributionPerSchedule.cumulativeDistribution,
          reachedAtLeastPct:
            DAUResult.distributionPerSchedule.cumulativeDistributionPercentage,
        });
      });
    });
  }

  private parseMrfResults(
    scheduleResults: ScheduleEvaluationResponse[],
    targets: Target[],
    currentSchedule: Schedule
  ) {
    if (!scheduleResults) return;

    let target: Target = null;
    scheduleResults.forEach((mrfResult) => {
      const mrfId = mrfResult.scheduleId;
      mrfResult.results.forEach((result) => {
        const universe = result.universe;
        target = targets[result.targetId];

        // extract vehicle data
        result.distributionPerVehicle.forEach((vehResult) => {
          // use the target vehicle to get the vehicle reference back in the mrf file
          const targetVehicle = target.vehicles.find(
            (vehicle) => vehicle.multiSurveyConfig?.vehicleRef === vehResult.id
          );
          const schVehicle = currentSchedule.vehicle(target, targetVehicle.id);

          let customReach000 = undefined;
          let reach = this.calculateReach(
            vehResult.distribution.distribution,
            universe
          );
          if (vehResult.distribution.reachPercentage) {
            customReach000 = vehResult.distribution.reach;
            reach = vehResult.distribution.reachPercentage / 100;
          }

          const ins = schVehicle.result.inserts * schVehicle.result.duration;
          targetVehicle.audience = ins
            ? vehResult.distribution.impressions / ins
            : 0;

          schVehicle.result.addResults({
            reach: reach,
            customReach000,
            population: universe,
            impressions: vehResult.distribution.impressions,
            effectiveReach: vehResult.distribution.effectiveReach || 0,
            effectiveReachPct:
              vehResult.distribution.effectiveReachPercentage || 0,
            totalCost: vehResult.distribution.cost,
            cpp: vehResult.distribution.costPerPoint,
            cpm: vehResult.distribution.costPerThousand,
          });
        });

        // extract group total data
        result.distributionPerVehicleGroup.forEach((groupResult) => {
          // find a vehicle that uses that group reference to get the mediatype name for storing results

          const groupRefId =
            groupResult.id.indexOf('mediatype|') === 0
              ? groupResult.id
              : `mediatype|${groupResult.id}`;
          const targetVehicle = target.vehicles.find(
            (vehicle) => vehicle.multiSurveyConfig?.groupRef === groupRefId
          );

          const group = currentSchedule.getScheduleTotal(
            targetVehicle.mediaType,
            ScheduleTotalTag.multiSurvey,
            target
          );

          let customReach000 = undefined;
          let reach = this.calculateReach(
            groupResult.distribution.distribution,
            universe
          );
          if (groupResult.distribution.reachPercentage) {
            customReach000 = groupResult.distribution.reach;
            reach = groupResult.distribution.reachPercentage / 100;
          }

          group.result.addResults({
            reach,
            customReach000,
            population: universe,
            impressions: groupResult.distribution.impressions,
            effectiveReach: groupResult.distribution.effectiveReach || 0,
            effectiveReachPct:
              groupResult.distribution.effectiveReachPercentage || 0,
            totalCost: groupResult.distribution.cost,
            cpp: groupResult.distribution.costPerPoint,
            cpm: groupResult.distribution.costPerThousand,
          });
        });

        // a case where there are no group results - extract the totals from the distrubtionPerSchedule object instead
        // usually when there's only one mediatype (UK Audioplannner).
        // results are collected in the schedule object
        if (
          result.distributionPerSchedule &&
          result.distributionPerVehicleGroup.length === 0
        ) {
          const targetVehicle = target.vehicles.find(
            (vehicle) => vehicle.multiSurveyConfig?.multiSurveyId === mrfId
          );

          const group = currentSchedule.getScheduleTotal(
            targetVehicle.mediaType,
            ScheduleTotalTag.multiSurvey,
            target
          );

          const mrfSchedResult = result.distributionPerSchedule;

          let customReach000 = undefined;
          let reach = this.calculateReach(
            mrfSchedResult.distribution,
            universe
          );
          if (mrfSchedResult.reachPercentage) {
            customReach000 = mrfSchedResult.reach;
            reach = mrfSchedResult.reachPercentage / 100;
          }

          group.result.addResults({
            reach,
            customReach000,
            population: universe,
            impressions: mrfSchedResult.impressions,
            effectiveReach: mrfSchedResult.effectiveReach || 0,
            effectiveReachPct: mrfSchedResult.effectiveReachPercentage || 0,
            reachedExactly: mrfSchedResult.distribution,
            reachedExactlyPct: mrfSchedResult.distributionPercentage,
            reachedAtLeast000: mrfSchedResult.cumulativeDistribution,
            reachedAtLeastPct: mrfSchedResult.cumulativeDistributionPercentage,
          });
        }
      });
    });
  }

  private parseManualResults(
    scheduleResults: ScheduleCombinedResponse[],
    targets: Target[],
    currentSchedule: Schedule
  ) {
    scheduleResults.forEach((scheduleResult) => {
      scheduleResult.results.forEach((manualResult) => {
        const universe = manualResult.universe;
        const target = targets[manualResult.targetId];

        const [targetVehicleId, sIndex] =
          scheduleResult.scheduleId.split(SCH_DELIMITER);
        const targetVehicle = target.vehicle(targetVehicleId);
        const scheduleVehicle = currentSchedule.vehicle(
          target,
          scheduleResult.scheduleId
        );

        const mediaTypeTotal = currentSchedule.getScheduleTotal(
          targetVehicle.mediaType,
          [ScheduleTotalTag.multiSurvey, ScheduleTotalTag.mediatype],
          target
        );

        const results: Metrics = {
          customReach000: manualResult.distributionPerSchedule.reach,
          reach: manualResult.distributionPerSchedule.reachPercentage / 100,
          population: universe,
          impressions: manualResult.distributionPerSchedule.impressions,
          effectiveReach: manualResult.distributionPerSchedule.effectiveReach,
          effectiveReachPct:
            manualResult.distributionPerSchedule.effectiveReachPercentage,
          totalCost: manualResult.distributionPerSchedule.cost,
          reachedExactly: manualResult.distributionPerSchedule.distribution,
          reachedExactlyPct:
            manualResult.distributionPerSchedule.distributionPercentage || [],
          reachedAtLeast000:
            manualResult.distributionPerSchedule.cumulativeDistribution || [],
          reachedAtLeastPct:
            manualResult.distributionPerSchedule
              .cumulativeDistributionPercentage || [],
          cpp: manualResult.distributionPerSchedule.costPerPoint || 0,
          cpm: manualResult.distributionPerSchedule.costPerThousand || 0,
        };

        mediaTypeTotal.result.addResults(results);
        scheduleVehicle.result.addResults(results);
      });
    });
  }

  private parseCombinedResults(
    combinedResults: CombinedResponseResults[],
    targets: Target[],
    currentSchedule: Schedule
  ) {
    combinedResults.forEach((targetCombinedResult) => {
      const target = targets[targetCombinedResult.targetId];
      const universe = targetCombinedResult.universe;

      const schedByTarget = currentSchedule.getScheduleTotal(
        tagToString(ScheduleTotalTag.total),
        ScheduleTotalTag.total,
        target
      );
      schedByTarget.result.addResults({
        reach: this.calculateReach(
          targetCombinedResult.distributionPerSchedule.distribution,
          universe
        ),
        esgScore: targetCombinedResult.distributionPerSchedule.ESG || -1,
        esgStability:
          targetCombinedResult.distributionPerSchedule.ESGStability || -1,
        impressions: targetCombinedResult.distributionPerSchedule.impressions,
        totalCost: targetCombinedResult.distributionPerSchedule.cost,
        cpp: targetCombinedResult.distributionPerSchedule.costPerPoint,
        cpm: targetCombinedResult.distributionPerSchedule.costPerThousand,
        reachedExactlyPct:
          targetCombinedResult.distributionPerSchedule.distributionPercentage,
        reachedAtLeast000:
          targetCombinedResult.distributionPerSchedule.cumulativeDistribution,
        reachedExactly:
          targetCombinedResult.distributionPerSchedule.distribution,
        reachedAtLeastPct:
          targetCombinedResult.distributionPerSchedule
            .cumulativeDistributionPercentage,
        effectiveReach:
          targetCombinedResult.distributionPerSchedule.effectiveReach || 0,
        impressionsFD:
          targetCombinedResult.distributionPerSchedule.distributionImpressions,
        effectiveReachPct:
          targetCombinedResult.distributionPerSchedule
            .effectiveReachPercentage || 0,
      });
    });
  }

  // consuming the results from the evaluation back into the schedule
  private populateScheduleWithData(
    data: EvaluateMediaPlanResponseResults[],
    targets: Target[],
    exclusiveRchGroups: string[],
    currentSchedule: Schedule,
    singleBroadcastProcessing: boolean = false // called from 'evaluateSingleBroadcastMediaplan()' only
  ) {
    // for each target
    const combiningSchedules = currentSchedule.name === TOTAL_SCHEDULE_NAME;

    data.forEach((resultTarget) => {
      const target = targets[resultTarget.targetId];
      const universe = resultTarget.universe;
      if (!singleBroadcastProcessing)
        currentSchedule.clearTotals(target, combiningSchedules);

      // extract vehicle results
      resultTarget.distributionPerVehicle.forEach((veh) => {
        const reach = this.calculateReach(
          veh.distribution.distribution,
          universe
        );

        const [vehicleWithScheduleIndex, broadcastId, weekString] =
          veh.id.split('|');
        const [vehicleId, scheduleIndex] =
          vehicleWithScheduleIndex.split(SCH_DELIMITER); // possibly combiningSchedules

        const targetVehicle = target.vehicle(vehicleId); // if vehicle not present, then it's a daypart

        // LOCATE SPOTPLAN AND ADD RESULTS TO THE CORRECT DAYPART
        if (!targetVehicle) {
          const weekValue = weekString ? parseInt(weekString) : -1;
          if (weekValue !== -1) {
            let daypartId = vehicleId;
            let broadcastIdSchedule = broadcastId;

            if (typeof scheduleIndex !== 'undefined') {
              daypartId = `${daypartId}${SCH_DELIMITER}${scheduleIndex}`;
            }
            const spotplan =
              currentSchedule.spotplans.findSpotplan(broadcastIdSchedule);
            const daypart = spotplan.daypart(daypartId);

            const dpResult = daypart.result.addResults(weekValue, target.id, {
              reachPct: reach * 100,
              reach000: reach * target.population,
              impressions: veh.distribution.impressions,
              totalCost: veh.distribution.cost,
              cpp: veh.distribution.costPerPoint,
              cpm: veh.distribution.costPerThousand,
              baseCpm: veh.distribution.costPerThousandBaseTarget,
              GRPs: target.population
                ? (veh.distribution.impressions / target.population) * 100
                : 0,
              dirty: false,
            });

            // update vehicle unit cost from total cost if costMethod was not planning on unit cost itself
            if (dpResult.costMethod !== CostMethod.unitCost) {
              const unitCost = dpResult.inserts
                ? (dpResult.totalCost || 0) / dpResult.inserts
                : 0;
              dpResult.addResults({ unitCost });
            }
          } // weekValue
        } else {
          //ADD RESULTS TO REGULAR VEHICLE
          const vehicle = currentSchedule.vehicle(target, vehicleId);
          vehicle.result.addResults({
            population: universe,
            reach,
            effectiveReach: veh.distribution.effectiveReach || 0,
            effectiveReachPct: veh.distribution.effectiveReachPercentage || 0,
            impressions: veh.distribution.impressions,
            esgScore: veh.distribution.ESG || -1,
            esgStability: veh.distribution.ESGStability || -1,
            totalCost: veh.distribution.cost,
            cpp: veh.distribution.costPerPoint,
            cpm: veh.distribution.costPerThousand,
            buyingCpm: veh.distribution.costPerThousandBuyingTarget,
            buyingCpp: veh.distribution.costPerPointBuyingTarget,
            ...(veh.exclusiveReach
              ? { uniqueReach000: veh.exclusiveReach }
              : undefined),
            baseCpm: veh.distribution.costPerThousandBaseTarget,
            dirty: false,
          });

          // update vehicle unit cost from total cost if costMethod was not planning on unit cost itself
          if (vehicle.result.costMethod !== CostMethod.unitCost) {
            const unitCost =
              vehicle.result.inserts * vehicle.result.duration
                ? vehicle.result.totalCost /
                  (vehicle.result.inserts * vehicle.result.duration)
                : 0;
            vehicle.result.addResults({ unitCost, dirty: false });
          }
        }
      }); // dist by veh

      // PARSING RESULTS FROM A SINGLE BROADCAST VEHICLE
      if (singleBroadcastProcessing) {
        // parse the groups. This will contain the broadcast total as well as a group for any of the weeks that required calculating
        let broadcastId = '';
        resultTarget.distributionPerVehicleGroup.forEach((resultGroup) => {
          const [group, reference, weekString, dayString] =
            resultGroup.group.split('|');
          broadcastId = reference;

          const reach = this.calculateReach(
            resultGroup.distribution.distribution,
            universe
          );

          // ensure we're working with broadcast groups
          if (group === 'broadcast') {
            const weekValue = weekString ? parseInt(weekString) : -1;
            const dayValue = dayString ? parseInt(dayString) : -1;
            const broadcastVehicle = currentSchedule.vehicle(target, reference);

            // broadcast total by week. group included the week (specific week)
            if (weekValue !== -1 || dayValue !== -1) {
              const spotplan = currentSchedule.spotplans.findSpotplan(
                broadcastVehicle.vehicleId
              );
              const results = {
                reach000: reach * universe,
                reachPct: reach * 100,
                impressions: resultGroup.distribution.impressions,
                GRPs: universe
                  ? (resultGroup.distribution.impressions / universe) * 100
                  : 0,
                cpp: resultGroup.distribution.costPerPoint,
                cpm: resultGroup.distribution.costPerThousand,
                baseCpm: resultGroup.distribution.costPerThousandBaseTarget,
                unitCost: 0,
                totalCost: 0,
              };

              if (dayValue !== -1) {
                spotplan.setWeekTotalByDayTotal(
                  dayValue,
                  weekValue,
                  broadcastVehicle.targetId,
                  results
                );
              } else {
                spotplan.setWeekTotal(
                  weekValue,
                  broadcastVehicle.targetId,
                  results
                );
              }
            }
          }
        });

        // As we're processing singleBroadcastProcessing, the schedule total is now only for the broadcast total
        // build the unit cost from the total cost
        const broadcastVehicle = currentSchedule.vehicle(target, broadcastId);
        const mediaVehicle = target.vehicle(broadcastVehicle.vehicleId);
        const unitCost =
          resultTarget.distributionPerSchedule.cost /
          mediaVehicle.dayparts.length;

        broadcastVehicle.result.addResults({
          population: universe,
          reach: this.calculateReach(
            resultTarget.distributionPerSchedule.distribution,
            universe
          ),
          effectiveReach:
            resultTarget.distributionPerSchedule.effectiveReach || 0,
          effectiveReachPct:
            resultTarget.distributionPerSchedule.effectiveReachPercentage || 0,
          impressions: resultTarget.distributionPerSchedule.impressions,
          esgScore: resultTarget.distributionPerSchedule.ESG || -1,
          esgStability: resultTarget.distributionPerSchedule.ESGStability || -1,
          totalCost: resultTarget.distributionPerSchedule.cost,
          cpp: resultTarget.distributionPerSchedule.costPerPoint,
          cpm: resultTarget.distributionPerSchedule.costPerThousand,
          unitCost,
        });
      } //singleBroadcastProcessing

      // if broadcast processing, then the full schedule was not put up for evaluation so dont expect total schedule results
      // this only contains broadcast vehicle results only (which have already ben catured above)
      if (!singleBroadcastProcessing) {
        // extract total mediaType schedule results using their mediaType
        // should also find the daypart grouped for the boradcast here
        resultTarget.distributionPerVehicleGroup.forEach((resultGroup) => {
          const [group, reference, weekString, dayString] =
            resultGroup.group.split('|');
          const reach = this.calculateReach(
            resultGroup.distribution.distribution,
            universe
          );

          // broadcast (dayparts collection) passed as a group, so build here
          // reference is not encoded with schedule, so results going back into AT0001 with no _schedIndex
          if (group === 'broadcast') {
            const vehicle = currentSchedule.vehicle(target, reference);
            if (vehicle && resultGroup.exclusiveReach && !weekString) {
              vehicle.result.addResults({
                uniqueReach000: resultGroup.exclusiveReach,
              });
            }
            const weekValue = weekString ? parseInt(weekString) : -1;
            const dayValue = dayString ? parseInt(dayString) : -1;
            const broadcastVehicle = currentSchedule.vehicle(target, reference);

            // broadcast total (no week indicator was found, so all weeks together)
            if (weekValue === -1 && dayValue === -1) {
              // build the unit cost from the total cost

              let inserts = broadcastVehicle.result.inserts;
              if (combiningSchedules) {
                const scheduleVehicles = currentSchedule.vehicles.filter(
                  (vehicle) =>
                    vehicle.targetId === target.id &&
                    vehicle.vehicleId.startsWith(
                      `${broadcastVehicle.vehicleId}_`
                    )
                );

                // sum inserts from the other schedule vehicles
                inserts = 0;
                scheduleVehicles.forEach((scheduleVehicle) => {
                  inserts += scheduleVehicle.result.inserts;
                });
              }

              const mediaVehicle = target.vehicle(broadcastVehicle.vehicleId);
              const unitCost =
                resultGroup.distribution.cost / mediaVehicle.dayparts.length;

              broadcastVehicle.result.addResults({
                population: universe,
                reach,
                inserts,
                effectiveReach: resultGroup.distribution.effectiveReach || 0,
                effectiveReachPct:
                  resultGroup.distribution.effectiveReachPercentage || 0,
                impressions: resultGroup.distribution.impressions,
                esgScore: resultGroup.distribution.ESG || -1,
                esgStability: resultGroup.distribution.ESGStability || -1,
                totalCost: resultGroup.distribution.cost,
                cpp: resultGroup.distribution.costPerPoint,
                cpm: resultGroup.distribution.costPerThousand,
                baseCpm: resultGroup.distribution.costPerThousandBaseTarget,
                unitCost,
              });
            }

            // broadcast total by week. group included the week (specific week)
            // as results are writing back to non schedule specific spotplans, possibly need to create a spotplan
            // TODO: inserts will be wrong
            if (weekValue !== -1 || (weekValue === -1 && dayValue !== -1)) {
              const targetVehicle = target.vehicle(broadcastVehicle.vehicleId);
              const spotplan =
                currentSchedule.spotplans.addSpotplan(targetVehicle);
              const result = {
                reach000: reach * universe,
                reachPct: reach * 100,
                impressions: resultGroup.distribution.impressions,
                GRPs: universe
                  ? (resultGroup.distribution.impressions / universe) * 100
                  : 0,
                cpp: resultGroup.distribution.costPerPoint,
                cpm: resultGroup.distribution.costPerThousand,
                baseCpm: resultGroup.distribution.costPerThousandBaseTarget,
                unitCost: 0,
                totalCost: 0,
              };

              if (dayValue !== -1) {
                spotplan.setWeekTotalByDayTotal(
                  dayValue,
                  weekValue,
                  broadcastVehicle.targetId,
                  result
                );
              } else {
                spotplan.setWeekTotal(
                  weekValue,
                  broadcastVehicle.targetId,
                  result
                );
              }
            }
          } else {
            // all other groups represented with an enum

            currentSchedule
              .getScheduleTotal(reference, stringToTag(group), target)
              .result.addResults({
                population: universe,
                reach,
                effectiveReach: resultGroup.distribution.effectiveReach || 0,
                effectiveReachPct:
                  resultGroup.distribution.effectiveReachPercentage || 0,
                impressions: resultGroup.distribution.impressions,
                reachedExactly: resultGroup.distribution.distribution,
                reachedExactlyPct:
                  resultGroup.distribution.distributionPercentage,
                reachedAtLeast000:
                  resultGroup.distribution.cumulativeDistribution,
                reachedAtLeastPct:
                  resultGroup.distribution.cumulativeDistributionPercentage,
                impressionsFD: resultGroup.distribution.distributionImpressions,
                uniqueReach000:
                  exclusiveRchGroups.length === 1
                    ? reach * universe
                    : resultGroup.exclusiveReach,
                esgScore: resultGroup.distribution.ESG || -1,
                esgStability: resultGroup.distribution.ESGStability || -1,
                totalCost: resultGroup.distribution.cost,
                cpp: resultGroup.distribution.costPerPoint,
                cpm: resultGroup.distribution.costPerThousand,
                baseCpm: resultGroup.distribution.costPerThousandBaseTarget,
              });
          }
        });

        this.processAdditionalGroupTotals(target, currentSchedule);
      } // !singleBroadcastProcessing
    });
  }

  // calculate the insertions for each of the groups (inc. totals) based on the vehicle result.inserts
  private processAdditionalGroupTotals(target: Target, schedule: Schedule) {
    if (schedule.name === TOTAL_SCHEDULE_NAME) return;

    const mediaTypes: {
      [group: string]: { isMultiSurvey: boolean; inserts: number };
    } = { total: { isMultiSurvey: false, inserts: 0 } };

    target.vehicles.forEach((vehicle) => {
      mediaTypes[vehicle.mediaType] = mediaTypes[vehicle.mediaType] || {
        isMultiSurvey: vehicle.isMultiSurvey,
        inserts: 0,
      };
      const veh = schedule.vehicle(target, vehicle.id);
      mediaTypes[vehicle.mediaType].inserts += veh.result.inserts;
    });

    // Calculate the total inserts for all media types
    mediaTypes['total'].inserts = Object.values(mediaTypes).reduce(
      (total, medtype) => total + medtype.inserts,
      0
    );
    Object.keys(mediaTypes).forEach((group) => {
      const scheduleTotalTag =
        group === 'total'
          ? ScheduleTotalTag.total
          : mediaTypes[group].isMultiSurvey
          ? ScheduleTotalTag.multiSurvey
          : ScheduleTotalTag.mediatype;

      const res = schedule.getScheduleTotal(group, scheduleTotalTag, target);
      res.result.addResults({ inserts: mediaTypes[group].inserts });
    });
  }

  private getGroupsForEvaluation(
    groups: VehicleGroup[],
    vehicleId: string
  ): string[] {
    const prefix = tagToString(ScheduleTotalTag.vehicleGroup);
    const groupList: string[] = [];
    groups.forEach((group) => {
      for (let veh of group.vehicles) {
        if (veh.id === vehicleId || veh.daypartIds.includes(vehicleId)) {
          groupList.push(`${prefix}|${group.id}`);
          break;
        }
      }
    });
    return groupList;
  }

  /*
    Warm up function will ensure that we have a warm lambda when we actually need to use it.
    This is the target coding which is a stand along endpoint.
*/
  warmUpTargetCoding(): void {
    const duration = (Date.now() - this.lastWarmupTimeTargetCoding) / 1000;
    if (duration >= 30) {
      this.lastWarmupTimeTargetCoding = Date.now();
      console.log(
        'Making warmup call to' +
          environment.api.coding.endPoint.getTargetCoding
      );
      this.apiService.unobservedRequest(
        'POST',
        environment.api.cmp.url,
        environment.api.coding.endPoint.getTargetCoding,
        { body: { warmup: true } }
      );
    } else {
      console.log('Skipped warmup' + duration);
    }
  }

  /*
        Warm up function will ensure that we have a warm lambda when we actually need to use it.
        This is the evaluateMediaPlan which is the same endpoint as getaudiencefortargetset and getnaturaldeliveryschedule.
  */
  warmUpCmpEngine(): void {
    const duration = (Date.now() - this.lastWarmupTimeCmpEngine) / 1000;
    if (duration >= 30) {
      this.lastWarmupTimeCmpEngine = Date.now();
      console.log(
        'Making warmup call to' + environment.api.cmp.endPoint.evaluateMediaPlan
      );
      this.apiService.unobservedRequest(
        'POST',
        environment.api.cmp.url,
        environment.api.cmp.endPoint.evaluateMediaPlan,
        { body: { warmup: true } }
      );
    } else {
      console.log('Skipped warmup' + duration);
    }
  }

  warmUpEngine(): void {
    this.warmUpCmpEngine();
  }

  private clearMediaplan(schedule: Schedule, targets: Target[]) {
    targets.forEach((target) => {
      schedule.clearInserts(target, false);
      schedule.clearTotals(target);
    });
  }

  clearCostTotals(schedule: Schedule, targets: Target[]) {
    targets.forEach((target) => {
      schedule.clearTotals(target, true, true);
    });
  }

  // extract the reach (1+) from the distribution array or calculate effective reach for the received effectiveDeliveryLevel
  private calculateReach(
    distribution: number[],
    population: number,
    effectiveDeliveryLevel: number = 1
  ): number {
    const reach =
      distribution.length && population
        ? (population -
            distribution
              .slice(0, effectiveDeliveryLevel)
              .reduce((sum, value) => sum + value)) /
          population
        : 0;

    return Math.max(0, reach);
  }

  /**
   * Returns a list of calcuated audiences based on the supplied vehicles
   *
   * @param {string} surveyCode The survey code of the survey to tab against
   * @param {Target[]} targets Array of targets to evaluate
   * @param {TargetAudienceSetVehicle[]} vehicles Array of vehicles to evaluate. Results returned in the same format they're rerceived. No processing done
   * @returns TargetAudienceSetResponse
   */
  getAudiencesForTargetSet(
    surveyCode: string,
    targets: Target[],
    vehicles: TargetAudienceSetVehicle[],
    surveyList?: SurveyInfo[]
  ): Observable<TargetAudienceSetResponse> {
    return vehicles.length === 0
      ? of({ success: false, results: [] })
      : new Observable((observable) => {
          // get target json coding
          this.getTargetCoding(
            targets.map((target) => target.documentTarget)
          ).subscribe((success) => {
            const hasCustomPop: boolean = !!targets.find(
              (target) => target.documentTarget.customPopulation
            );
            let customPopulation;

            // get all the encoded target definitions
            const targetDefinitions: TargetStatementWithId[] = targets.map(
              (target, index) => {
                if (hasCustomPop)
                  customPopulation =
                    target.documentTarget.customPopulation ??
                    target.documentTarget.population;

                return {
                  id: index,
                  code: target.documentTarget.jsonCoding,
                  customPopulation,
                };
              }
            );

            // build vehicle id list.
            const vehs: VehicleDefinition[] = [];
            vehicles.forEach((vehicle: TargetAudienceSetVehicle) => {
              vehs.push({
                id: vehicle.id,
                mnemonic: vehicle.mnemonic,
                surveyCode: vehicle.surveyCode,
              });
            });

            // request body object
            const options: TargetAudienceSetMultiSurveyRequest = {
              surveyCode,
              vehicles: vehs,
              targetDefinitions,
              weightSet: this.HARDCODED_WEIGHT_TO_ONE,
              authorizationGroup: targets[0].survey.authorizationGroup,
              surveyList,
              engineMode: 'sync',
            };
            // Reset the warmup timer as we are making a call to the engine
            this.lastWarmupTimeCmpEngine = Date.now();
            this.apiService
              .request(
                'POST',
                environment.api.cmp.url,
                environment.api.cmp.endPoint
                  .getAudiencesForTargetSetMultisurvey,
                { body: options }
              )
              .pipe(
                catchError((err) => {
                  return of({ success: false, results: null });
                })
              )
              .subscribe((data: TargetAudienceSetResponse) => {
                observable.next(data);
                observable.complete();
              });
          });
        });
  }

  /**
   * convert a coding statement into A DocumentTarget for making engine queries
   *
   * @param {DocumentSurvey} survey The currently selected survey
   * @param {string} coding Coding statement
   * @returns DocumentTarget containing coding statement encoded
   */
  getTargetFromCoding(survey: DocumentSurvey, coding: string): DocumentTarget {
    const target: DocumentTarget = {
      survey,
      jsonCoding: { Operator: 'AND', Operands: [{ Val: 1 }] },
      coding,
    };

    target.targets = [
      {
        targets: [],
        coding,
        jsonCoding: { Operator: 'AND', Operands: [{ Val: 1 }] },
        operator: Operator.and,
        survey,
      },
    ];

    return target;
  }

  /**
   * Tab a target and return population and sample
   *
   * @param {DocumentTarget[]} targets DocumentTarget array containing the definition of the query
   * @returns TargetEvaluationResponse containing audeince and resps
   */
  getMultiTargetEvaluation(
    targets: DocumentTarget[],
    suppressErrors?: boolean
  ): Observable<MultiTargetEvaluationResponse> {
    if (!targets.length) return of(null);
    return new Observable((observable) => {
      // firstlty get the jsonCoding (result added back in objects)
      this.getTargetCoding(targets).subscribe((success) => {
        // request object
        const options: MultiTargetEvaluationRequest = {
          surveyCode: targets[0].survey.code,
          targetDefinitions: targets.map((tgt, id) => {
            return {
              id,
              code: tgt.jsonCoding,
              name: tgt.title,
              customPopulation: tgt.customPopulation,
            };
          }),
          weightIndex: this.HARDCODED_WEIGHT_TO_ONE,
          authorizationGroup:
            targets[0].survey.authorizationGroup || targets[0].survey.code,
          engineMode: 'sync',
          suppressErrors,
        };

        // Reset the warmup timer as we are making a call to the TargetEvaluation
        this.lastWarmupTimeTargetEvaluation = Date.now();
        const responseTime: APIResponseTimer = this.apiService.responseTimer();
        this.apiService
          .request(
            'POST',
            environment.api.cmp.url,
            environment.api.cmp.endPoint.getMultiTargetEvaluation,
            { body: options }
          )
          .pipe(
            catchError((err) => {
              const evalResponse = {
                ...EMPTY_EVALUATION_RESPONSE,
              };
              evalResponse.status.message = err;

              return of(evalResponse);
            })
          )
          .subscribe((data) => {
            //  RawTargetResponse

            if (data.success) {
              data.results.forEach((result) => {
                targets[result.targetId].population = result.universe;
                targets[result.targetId].sample = result.sample;
              });
            }

            // return results
            const res: MultiTargetEvaluationResponse = {
              status: {
                hits: 0,
                success: data.success,
                message: data.status?.message,
                statusCode: data.success ? 200 : 400,
                took: `${responseTime()} ms`,
              },
              populations: targets.map((tgt) => tgt.population),
              samples: targets.map((tgt) => tgt.sample),
            };

            observable.next(res);
            observable.complete();
          });
      });
    });
  }

  /**
   * Perform natural delivery within the given target for the vehicle list
   *
   * @param {DocumentTarget} target DocumentTarget to perform the natural delivery against
   * @param {NaturalDeliveryVehicle[]} vehicles NaturalDeliveryVehicle array. In the case of broadcast, these should be the dayparts, not the parent
   * @param {NaturalDeliveryGoalMetric} goal NaturalDeliveryGoalMetric: metric to use for natural delivery
   * @param {number} value value for the goal we're trying to obtain
   * @returns NaturalDeliveryResponse containing results by vehicle
   */
  public naturalDelivery(
    target: DocumentTarget,
    vehicles: NaturalDeliveryVehicle[],
    goal: NaturalDeliveryGoalMetric,
    value: number,
    surveyList: SurveyInfo[]
  ): Observable<NaturalDeliveryResponse> {
    return value === 0
      ? this.emptyNaturalDelivery(vehicles)
      : new Observable((observable) => {
          this.getTargetCoding([target]).subscribe(() => {
            const options: NaturalDeliveryRequest = {
              surveyCode: target.survey.code,
              targetDefinitions: [target].map((target, id) => {
                return { id, code: target.jsonCoding };
              }),
              targetUniverse: target.population,
              weightSet: this.HARDCODED_WEIGHT_TO_ONE,
              authorizationGroup: target.survey.authorizationGroup,
              vehicles,
              useIntegerInsertions: true,
              goal: { [goal]: value },
              engineMode:
                goal === NaturalDeliveryGoalMetric.Reach ? 'async' : 'sync',
              surveyList,
            };
            const naturalDeliveryRequest =
              goal === NaturalDeliveryGoalMetric.Reach
                ? this.naturalDeliveryAsync(options)
                : this.naturalDeliverySync(options);

            naturalDeliveryRequest.subscribe((data) => {
              observable.next(data);
              observable.complete();
            });
          }); // getTargetCoding
        });
  }

  /**
   * Perform natural delivery request using web socket when user entered reach data. In this case, backend processing might take longer
   */
  private naturalDeliveryAsync(
    options: NaturalDeliveryRequest
  ): Observable<NaturalDeliveryResponse> {
    return new Observable((observable) => {
      this.apiService
        .request(
          'GET',
          environment.api.websocket.url,
          environment.api.websocket.endPoint.websocketurl,
          { body: { engineMode: 'async' } }
        )
        .pipe(
          catchError((err) => {
            return of({
              success: false,
              results: [],
            });
          })
        )
        .subscribe((data) => {
          const websocketUrl = data.url;
          const browserTab = `${Date.now()}_${uniqid()}`;
          Auth.currentSession().then((session) => {
            const token = session.getIdToken().getJwtToken();
            let jobId: string = null;
            let runId: string = null;
            const connectionUrl = `${websocketUrl}?browser_tab=${browserTab}&idToken=${token}`;
            this.wsService.connect(connectionUrl);
            this.wsService
              .getMessageReceived(connectionUrl)
              .subscribe((message) => {
                if (message === CONNECTED) {
                  this.apiService
                    .request(
                      'POST',
                      environment.api.cmp.url,
                      environment.api.cmp.endPoint
                        .getNaturalDeliveryScheduleMultisurvey,
                      { body: options }
                    )
                    .pipe(
                      // any errors are reported by the api Service, so not just return success false for component cleanup
                      catchError((err) => {
                        this.wsService.closeConnection(connectionUrl, false);
                        return of({ success: false, results: [] });
                      })
                    )
                    .subscribe((data) => {
                      jobId = data.runId;
                      if (data.runId) {
                        this.wsService.sendMessage(connectionUrl, {
                          action: 'subscribe',
                          browser_tab: browserTab,
                          info: {
                            job_id: jobId,
                            engine: 'cmp',
                            endpoint:
                              environment.api.cmp.endPoint.getNaturalDeliveryScheduleMultisurvey.replace(
                                '/',
                                ''
                              ),
                          },
                        });
                      } else {
                        this.wsService.closeConnection(connectionUrl, false);
                        observable.next(data);
                        observable.complete();
                      }
                    });
                } else if (message === CONNECTION_CLOSED) {
                  if (runId) {
                    this.apiService
                      .request(
                        'POST',
                        environment.api.cmp.url,
                        environment.api.cmp.endPoint.getasyncresults,
                        { body: { runId, engineMode: 'async' } }
                      )
                      .pipe(
                        // any errors are reported by the api Service, so not just return success false for component cleanup
                        catchError((err) => {
                          this.wsService.closeConnection(connectionUrl, false);
                          return of({ success: false, results: [] });
                        })
                      )
                      .subscribe((data) => {
                        observable.next(data);
                        observable.complete();
                      });
                  } else {
                    this.wsService.connect(`${connectionUrl}&reconnect`, false);
                  }
                } else {
                  const parsedMessage = JSON.parse(message);
                  const { info } = parsedMessage;
                  if (info && info.success === true) {
                    if (info?.message.includes('error')) {
                      observable.next({
                        success: false,
                        results: [],
                        error: info?.message,
                      });
                      observable.complete();
                      this.wsService.closeConnection(connectionUrl, false);
                    } else {
                      runId = info.id;
                      this.wsService.sendMessage(connectionUrl, {
                        action: 'unsubscribe',
                        browser_tab: browserTab,
                        info: {
                          job_id: jobId,
                          engine: 'cmp',
                          endpoint:
                            environment.api.cmp.endPoint.getNaturalDeliveryScheduleMultisurvey.replace(
                              '/',
                              ''
                            ),
                        },
                      });
                    }
                  }
                  if (info && info.success === false) {
                    observable.next({
                      success: false,
                      results: [],
                      error: info?.message,
                    });
                    observable.complete();
                    this.wsService.closeConnection(connectionUrl, false);
                  }
                  if (info?.status === 'unsubscribed') {
                    this.wsService.closeConnection(connectionUrl);
                  }
                }
              });
          });
        });
    });
  }

  private naturalDeliverySync(
    options: NaturalDeliveryRequest
  ): Observable<NaturalDeliveryResponse> {
    return new Observable((observable) => {
      this.apiService
        .request(
          'POST',
          environment.api.cmp.url,
          environment.api.cmp.endPoint.getNaturalDeliveryScheduleMultisurvey,
          { body: options }
        )
        .pipe(
          // any errors are reported by the api Service, so not just return success false for component cleanup
          catchError((err) => {
            return of({ success: false, results: [] });
          })
        )
        .subscribe((data) => {
          observable.next(data);
          observable.complete();
        });
    });
  }

  /**
   * Provide the details of the current campaign for formatting into an MRF file object
   * MRF object produced by endpoint and returned through an observable
   *
   * @param {Target} target Primary target
   * @param {Target[]} targets Array of targets to evaluate
   * @param {Schedule[]} schedule object holding all vehicles and inserts to evaluate
   * @param {VehicleGroup[]} vehicles array containing a subset of vehicles for evaluation. optional
   * @param {MrfCampaignInfo} campaignInfo Additional details required about the current campaign
   * @returns {Observable<CreateMrfFileResponse>} MrfFile response object also contains the Mfrfile object itself
   */
  createMrfFile(
    target: Target,
    targets: Target[],
    schedule: Schedule,
    vehicleGroups: VehicleGroup[],
    campaignInfo: MrfCampaignInfo
  ): Observable<CreateMrfFileResponse> {
    return new Observable((observable) => {
      const planningTargets = targets.filter((target) => target.planningTarget);

      // planningTarget definitions
      const targetDefinitions: TargetStatementWithId[] = planningTargets.map(
        (target, id) => {
          return {
            id,
            code: target.documentTarget.jsonCoding,
            targetId: target.id,
            customPopulation: target.documentTarget.customPopulation,
          };
        }
      );

      // ESG
      const ESGProvider = target.survey.esgProviders?.length
        ? target.survey.esgProviders[0]
        : '';

      // Vehicle data
      const vehiclesScheduleResult = this.vehiclesSchedule(
        targets,
        schedule,
        vehicleGroups
      );

      const vehiclesReq: MrfFileVehicleRequest[] = [];
      vehiclesScheduleResult.vehiclesReq.forEach((vehReq) => {
        // a cost (cpm or cpp) definition - re-assign to the id from the buying Target definitions
        if (vehReq.costTargetId) {
          const planningTarget = targetDefinitions.find(
            (tgt) => tgt.targetId === vehReq.costTargetId
          );
          vehReq.costTargetId = planningTarget.id;
        }

        // add the additional params needed for the MRF export
        const req: MrfFileVehicleRequest = {
          ...vehReq,
          mediaUsage: [], //The vehicles on which the bought impressions are divided; for addressable vehicles only; mnemonic in that case is not needed
          composingVehicles: [], //The vehicles that make up the main vehicle
          isBuyingGroup: false, //Indicates the vehicle is a buyinggroup. In this case the impressions or desired reach must be passed in the goal property, and composingVehicles should be present
          //goal: { [OptimiseMetrics.grps] : 0  },
          info: 'hello',
        };

        vehiclesReq.push(req);
      });

      const mrfRequest: CreateMrfFileRequest = {
        surveyCode: target.survey.code,
        authorizationGroup: target.survey.authorizationGroup,
        weightSet: this.HARDCODED_WEIGHT_TO_ONE,
        warmup: false,
        deliveryByGroup: {},
        targetDefinitions,
        buyingTargetDefinitions: vehiclesScheduleResult.buyingTargetDefinitions,
        ESGProvider,
        vehicles: vehiclesReq,
        planningSystem: 'Plan',
        planningSystemLink: `${window.location.protocol}//${window.location.hostname}`,
        planningSystemFileName: `${campaignInfo.campaignTitle}.MRF`,
        ...campaignInfo,
      };

      this.apiService
        .request(
          'POST',
          environment.api.cmp.url,
          environment.api.cmp.endPoint.createMrfFile,
          { body: mrfRequest }
        )
        .pipe(
          // any errors are reported by the api Service, so not just return success false for component cleanup
          catchError((err) => {
            return of({ success: false, message: err.message, results: null });
          })
        )
        .subscribe((data: CreateMrfFileResponse) => {
          observable.next(data);
          observable.complete();
        });
    });
  }

  multiSurveyCompatibility(
    surveyCodes: string[]
  ): Observable<SurveyCompatibiltyResponse> {
    return this.apiService
      .request(
        'POST',
        environment.api.cmp.url,
        environment.api.cmp.endPoint.multiSurveyCompatibility,
        { body: { surveyCodes } }
      )
      .pipe(
        catchError((err) => {
          return of({ success: false });
        })
      );
  }

  private emptyNaturalDelivery(
    vehicles: NaturalDeliveryVehicle[]
  ): Observable<NaturalDeliveryResponse> {
    const vehicleResults: NaturalDeliveryResultResponse[] = vehicles.map(
      (vehicle) => {
        return {
          id: vehicle.id,
          numberOfInserts: 0,
          duration: undefined,
          buyingTargetId: undefined,
          frequencyCap: undefined,
          impressionsBought: undefined,
          mediaUsage: undefined,
        };
      }
    );

    return of({
      success: true,
      error: '',
      results: vehicleResults,
    });
  }

  // turn a standard coding string into a json coding object
  public getTargetCoding(targets: DocumentTarget[]): Observable<boolean> {
    // lowercase operators were coming from the call, so correcting here (needs correcting on endpoint)
    const fixCase = (jsonCoding: any): any => {
      if (jsonCoding.Operator)
        jsonCoding.Operator = jsonCoding.Operator.toUpperCase();
      if (jsonCoding.Operands) jsonCoding.Operands.forEach((op) => fixCase(op));
      return jsonCoding;
    };

    // only work on whats needed
    const workingList = targets.filter((target) => !target.jsonCoding);

    return workingList.length == 0
      ? of(true)
      : new Observable((observable) => {
          const options = {
            returnStatements: true,
            returnMnemonics: false,
            codingStatements: workingList.map((target) => target.coding),
          };

          const responseTime: APIResponseTimer =
            this.apiService.responseTimer();

          // We will want the target evaluation next so let's make sure that it is warmed up.
          this.lastWarmupTimeTargetCoding = Date.now();
          this.apiService
            .request(
              'POST',
              environment.api.coding.url,
              environment.api.coding.endPoint.getTargetCoding,
              { body: options }
            )
            .pipe(
              catchError((err) => {
                return of(false);
              })
            )
            .subscribe((codingResponse) => {
              const success =
                codingResponse.convertedTargets &&
                codingResponse.convertedTargets.filter((val) => val != null)
                  .length === workingList.length;
              if (success) {
                workingList.forEach((target, index) => {
                  target.jsonCoding = fixCase(
                    codingResponse?.convertedTargets[index]
                  );
                });
              }
              codingResponse.took = `${responseTime()} ms`;
              observable.next(success);
              observable.complete();
            });
        });
  }
}
