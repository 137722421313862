import { Injectable } from '@angular/core';
import { Observable, Subscriber, forkJoin } from 'rxjs';
import { CostMethod, Metrics, ResultType } from '../classes/result';
import { Schedule } from '../classes/schedule';
import { Target } from '../classes/target';
import {
  ScheduleVehicle,
  TargetDaypart,
  TargetVehicle,
} from '../classes/vehicle';
import { TreeTableEditEvent } from '../components/tree-table/tree-table.models';
import {
  NaturalDeliveryGoalMetric,
  NaturalDeliveryResponse,
  NaturalDeliveryResponseMessage,
  NaturalDeliveryVehicle,
  SurveyInfo,
} from '../models/engine.media-evaluation.models';
import { EngineService } from './engine.service';
import { MediaPlannerService } from './media-planner.service';
import { VehicleGroup } from '../classes/vehicle-groups';
import {
  Column_BuyingGRPS,
  Column_BuyingImpressions,
  Column_CPP,
  Column_UnitCost,
  Column_Duration,
  Column_FreqCapping,
  Column_GRPs,
  Column_Impressions,
  Column_Inserts,
  Column_Reach000,
  Column_ReachPct,
  Column_InsertsDM,
  Column_DurationDM,
  Column_NumberOfMailItems,
  Column_CPM,
  Column_BaseCPM,
  Column_BuyingCPM,
  Column_BuyingCPP,
  Column_TotalCost,
} from '../models/planning-veh-columns.models';
import { defaultIfEmpty } from 'rxjs/operators';
import { RowContents, RowType } from '../models/planning-columns.models';
import {
  DaypartMetrics,
  EMPTY_DAYPART_RESULTS,
} from '../classes/daypart-result';
import {
  SpotplanStrategy,
  SpotplanAllocation,
  SpotplanSchedule,
  SpotplanScheduleDaypart,
} from '../classes/spotplan-schedule';
import * as Spot_Columns from '../models/spot-columns.models';
import { PlanningValueProviderService } from './planning-value-provider.service';
import { ManualInputVehicleRequest } from '../models/engine.evaluate-multi-media-plan.models';

export interface ProcessInputResponse {
  success: boolean;
  reachRequired: boolean;
  messages: string[];
}

const ZERO_INSERTS_MSG =
  'Your input generates an insert or spot that is less than 1 which therefore displays your input as 0';

const ZERO_COSTS_MSG = 'Enter costs before planning by Total cost';

const NATURAL_DELIVERY_MIN_AUDIENCE = 0;

@Injectable({
  providedIn: 'root',
})
export class PlanningService {
  constructor(
    private engineService: EngineService,
    private mediaplannerService: MediaPlannerService,
    private planningValueProviderService: PlanningValueProviderService
  ) {}

  // receive schedule editing of any metrics, process across multiple targetsd and initiate a R&F if required.
  processVehicle(
    event: TreeTableEditEvent,
    currentTarget: Target,
    targets: Target[],
    vehicle: TargetVehicle,
    schedule: Schedule,
    value: number
  ): Observable<ProcessInputResponse> {
    return new Observable((observable) => {
      let inserts = 0;
      let duration = 1;
      let popn = 0;
      let reachRequired = false;
      let unitCost = 0;

      let baseTarget: Target = null;
      let baseVehicle: TargetVehicle = null;

      const vehicleId = vehicle.id;
      const messages: string[] = [];
      const requests = [];

      // reject the column entry if it should not happen
      if (!this.getVehicleEditableByColumn(vehicle, event.columnDef)) {
        observable.next({ messages, success: true, reachRequired });
        observable.complete();
        return;
      }

      const isPercentage =
        this.mediaplannerService.plan.surveyMetaData.reachFreq.isPercentageInserts(
          vehicle
        );

      value = value || 0;

      // get a parsed number from the input
      switch (event.columnDef) {
        // inserts input
        case Column_Inserts.columnDef:
          reachRequired = true;
          inserts = value;

          // make a percentage is required
          if (isPercentage) {
            if (inserts > 100) {
              // increase duration if needed, else preserve current
              duration = Math.max(
                schedule.vehicle(currentTarget, vehicleId).result.duration || 1,
                Math.ceil(inserts / 100) || 1
              );

              inserts = inserts / duration / 100;

              targets.forEach((target) => {
                schedule.addResults(target, vehicleId, { duration });
              });
            } else {
              inserts = Math.min(100, inserts) / 100;
            }
          }

          // make integer if not media vehicle
          if (!vehicle.isMediaGroup && !isPercentage) {
            inserts = Math.floor(inserts);
          }

          targets.forEach((target) => {
            schedule.addInserts(target, vehicleId, inserts);
          });
          break;

        // duration input
        case Column_Duration.columnDef:
          reachRequired = true;
          duration = Math.max(1, value);

          targets.forEach((target) => {
            schedule.addResults(target, vehicleId, { duration });
          });
          break;

        // GRP input
        case Column_GRPs.columnDef:
          if (vehicle.addressableConfig) break;
          reachRequired = true;

          inserts =
            currentTarget.population && vehicle.grossAudience
              ? value /
                ((vehicle.grossAudience / currentTarget.population) * 100)
              : 0;

          duration = 1;

          // treat as a percentage if required
          if (isPercentage) {
            if (inserts > 1) {
              // increase duration if needed, else preserve current
              duration = Math.max(
                schedule.vehicle(currentTarget, vehicleId).result.duration || 1,
                Math.ceil(inserts) || 1
              );
            }
            inserts = inserts / duration;
          }

          // round if not media vehicle
          if (!vehicle.isMediaGroup && !isPercentage) {
            inserts = Math.round(inserts);
          }

          if (inserts === 0 && value > 0) messages.push(ZERO_INSERTS_MSG);

          targets.forEach((target) => {
            schedule.addResults(target, vehicleId, { inserts, duration });
          });

          break;

        // Impressions input
        case Column_Impressions.columnDef:
          if (vehicle.addressableConfig) break;
          reachRequired = true;
          inserts = vehicle.grossAudience ? value / vehicle.grossAudience : 0;
          duration = 1;

          // treat as a percentage if required
          if (isPercentage) {
            if (inserts > 1) {
              // increase duration if needed, else preserve current
              duration = Math.max(
                schedule.vehicle(currentTarget, vehicleId).result.duration || 1,
                Math.ceil(inserts) || 1
              );
            }
            inserts = inserts / duration;
          }

          // round if not media vehicle
          if (!vehicle.isMediaGroup && !isPercentage) {
            inserts = Math.round(inserts);
          }

          if (inserts === 0 && value > 0) messages.push(ZERO_INSERTS_MSG);
          targets.forEach((target) => {
            schedule.addResults(target, vehicleId, { inserts, duration });
          });
          break;

        // addressable media, buying impressions
        case Column_BuyingImpressions.columnDef:
          if (!vehicle.addressableConfig) break;

          popn = targets.find(
            (target) => target.id === vehicle.addressableConfig.targetId
          ).population;
          const buyingImpressions = value;
          const grps = popn ? (value / popn) * 100 : 0;

          reachRequired = true;
          targets.forEach((target) => {
            schedule.addResults(target, vehicleId, {
              buyingImpressions,
              buyingGrps: grps,
            });
          });

          break;

        // addressable media, buying GRPs
        case Column_BuyingGRPS.columnDef:
          if (!vehicle.addressableConfig) break;

          popn = targets.find(
            (target) => target.id === vehicle.addressableConfig.targetId
          ).population;
          const buyingGrps = value;
          let imp = (value / 100) * popn;

          reachRequired = true;
          targets.forEach((target) => {
            schedule.addResults(target, vehicleId, {
              buyingGrps,
              buyingImpressions: imp,
            });
          });
          break;

        // Frequency Capping
        case Column_FreqCapping.columnDef:
          if (!vehicle.addressableConfig) break;

          const freqCapping = value;
          reachRequired = true;
          targets.forEach((target) => {
            schedule.addResults(target, vehicleId, { freqCapping });
          });
          break;

        case Column_ReachPct.columnDef:
        case Column_Reach000.columnDef:
          reachRequired = true;

          value =
            event.columnDef === Column_Reach000.columnDef
              ? (value / currentTarget.population) * 100
              : value;
          requests.push(
            this.naturalDelivery(
              [vehicle],
              currentTarget,
              targets,
              schedule,
              NaturalDeliveryGoalMetric.Reach,
              value
            )
          );

          break;

        case Column_InsertsDM.columnDef:
          if (!vehicle.addressableConfig) break;
          inserts = value;
          reachRequired = true;
          popn = vehicle.addressableConfig.population;

          if (isPercentage) {
            inserts = Math.min(100, inserts) / 100;
          }
          // mail items = buying audience population * (inserts * duration)
          const noOfMailItems = popn * inserts * duration;

          targets.forEach((target) => {
            schedule.addResults(target, vehicleId, {
              noOfMailItems,
              insertsDM: inserts,
            });
          });

          break;

        case Column_NumberOfMailItems.columnDef:
          if (!vehicle.addressableConfig) break;

          popn = vehicle.addressableConfig.population;
          inserts = popn ? value / popn : 0;
          duration = 1;

          if (inserts > 1) {
            duration = Math.ceil(inserts);
            inserts = inserts / duration;
          }
          reachRequired = true;
          targets.forEach((target) => {
            schedule.addResults(target, vehicleId, {
              insertsDM: inserts,
              durationDM: duration,
              noOfMailItems: value,
            });
          });

          break;
        case Column_DurationDM.columnDef:
          reachRequired = true;
          popn = vehicle.addressableConfig.population;
          value = value < 1 ? 1 : value;

          targets.forEach((target) => {
            schedule.addResults(target, vehicleId, { durationDM: value });
          });
          break;

        // Costs input
        case Column_UnitCost.columnDef:
          reachRequired = true;
          targets.forEach((target) => {
            schedule.addCosts(target, vehicleId, value, CostMethod.unitCost);
          });
          break;

        // Total Costs input
        case Column_TotalCost.columnDef:
          reachRequired = true;
          let reportedNoCost = false;
          const totalCost = value;

          targets.forEach((target) => {
            const veh = schedule.vehicle(target, vehicleId);

            // calcuate the inserts to honour the unit cost with the total cost change
            const unitCost = veh.result.unitCost || 0;
            if (unitCost) {
              let inserts = totalCost / unitCost;
              let duration =
                schedule.vehicle(currentTarget, vehicleId).result.duration || 1;

              // make a percentage if required and handle duration
              if (isPercentage) {
                if (inserts > 1) {
                  // increase duration if needed, else preserve current
                  duration = Math.max(duration, Math.ceil(inserts) || 1);
                }
                inserts = inserts / duration;
              }

              // make integer if not media vehicle
              if (!vehicle.isMediaGroup && !isPercentage) {
                inserts = Math.floor(inserts);
              }

              schedule.addResults(target, vehicleId, {
                inserts,
                duration,
                totalCost,
                costMethod: CostMethod.unitCost,
              });
            } else {
              if (!reportedNoCost)
                messages.push(`${ZERO_COSTS_MSG} for "${vehicle.title}"`);
              reportedNoCost = true;
            }
          });
          break;

        // CPP Input
        case Column_CPP.columnDef:
          reachRequired = true;

          targets.forEach((target) => {
            // calculate unit cost
            const targetVehicle = target.vehicle(vehicleId);
            unitCost =
              value * ((targetVehicle.audience / target.population) * 100);
            schedule.addResults(target, vehicleId, { unitCost });

            schedule.addCosts(
              target,
              vehicleId,
              value,
              CostMethod.cpp,
              currentTarget.id
            );
          });
          break;

        // CPM Input
        case Column_CPM.columnDef:
          reachRequired = true;
          baseTarget = this.mediaplannerService.plan.baseTarget;

          targets.forEach((target) => {
            // calculate unit cost
            const targetVehicle = target.vehicle(vehicleId);
            baseVehicle = baseTarget.vehicleByMnemonic(targetVehicle.mnemonic);
            unitCost = value * targetVehicle.audience;
            schedule.addResults(target, vehicleId, { unitCost });

            schedule.addCosts(
              target,
              vehicleId,
              value,
              CostMethod.cpm,
              currentTarget.id
            );

            if (baseVehicle) {
              const baseCpm = baseVehicle.audience
                ? unitCost / baseVehicle.audience
                : 0;
              schedule.addResults(target, vehicleId, { baseCpm });
            }
          });
          break;

        case Column_BaseCPM.columnDef:
          reachRequired = true;
          baseTarget = this.mediaplannerService.plan.baseTarget;

          targets.forEach((target) => {
            const targetVehicle = target.vehicle(vehicleId);
            baseVehicle = baseTarget.vehicleByMnemonic(targetVehicle.mnemonic);

            if (baseVehicle) {
              // calculate unit cost
              unitCost = value * baseVehicle.audience;
              const cpm = targetVehicle.audience
                ? unitCost / targetVehicle.audience
                : 0;
              schedule.addResults(target, vehicleId, { cpm });

              schedule.addResults(target, vehicleId, { unitCost });
              schedule.addCosts(
                target,
                vehicleId,
                value,
                CostMethod.baseCpm,
                currentTarget.id
              );
            }
          });
          break;

        // Buying CPM Input
        case Column_BuyingCPM.columnDef:
          reachRequired = true;
          targets.forEach((target) => {
            //const veh = schedule.vehicle(target, vehicleId);
            schedule.addResults(target, vehicleId, {
              buyingCpm: value,
              costMethod: CostMethod.cpm,
            });
          });
          break;

        // Buying CPP Input
        case Column_BuyingCPP.columnDef:
          reachRequired = true;
          targets.forEach((target) => {
            //const veh = schedule.vehicle(target, vehicleId);
            schedule.addResults(target, vehicleId, {
              buyingCpp: value,
              costMethod: CostMethod.cpp,
            });
          });
          break;

        default:
          messages.push(`Table input not captured '${event.columnDef}'`);
          break;
      }

      forkJoin(requests)
        .pipe(defaultIfEmpty(null))
        .subscribe((data) => {
          // messages to add from the deferred calls
          if (data && data.length) {
            const msgs = data
              .filter((dat) => dat.message)
              .map((dat) => dat.message);
            messages.push(...msgs);
          }

          observable.next({ messages, success: true, reachRequired });
          observable.complete();
        });
    });
  }

  // using the vehicle and column, deterine if that cell can be edited
  getVehicleEditableByColumn(
    vehicle: TargetVehicle,
    columnDef: string
  ): boolean {
    const editable =
      this.planningValueProviderService.getVehicleEditable(vehicle);
    return typeof editable[columnDef] === 'undefined'
      ? true
      : editable[columnDef];
  }

  // process broadcast inputs, call natural delivery to determine the daypart spread
  processBroadcast(
    event: TreeTableEditEvent,
    currentTarget: Target,
    targets: Target[],
    vehicle: TargetVehicle,
    schedule: Schedule,
    value: number,
    week: number = -1
  ): Observable<ProcessInputResponse> {
    value = value || 0;

    return new Observable((observable) => {
      const handleComplete = () => {
        observable.next({
          messages,
          success,
          reachRequired,
        });
        observable.complete();
      };

      let reachRequired = true;
      let success = true;
      const messages: string[] = [];
      let handled = false;

      const planning = [
        Column_GRPs.columnDef,
        Column_Impressions.columnDef,
        Column_ReachPct.columnDef,
        Column_Reach000.columnDef,
      ];
      const costings = [
        Column_UnitCost.columnDef,
        Column_CPM.columnDef,
        Column_CPP.columnDef,
        Column_BaseCPM.columnDef,
      ];

      // get (or create) the spotplan used with this vehicle
      const spotplan = schedule.spotplans.addSpotplan(vehicle);

      // process total cost, ends up in a GRP natural delivery request
      if (event.columnDef === Column_TotalCost.columnDef) {
        const schVehicle = schedule.vehicle(currentTarget, vehicle.id);

        let grps = 0;

        switch (schVehicle.result.costMethod) {
          case CostMethod.cpp:
            grps = schVehicle.result.cpp ? value / schVehicle.result.cpp : 0;
            break;

          case CostMethod.cpm:
            grps =
              schVehicle.result.cpm * currentTarget.population
                ? (value / schVehicle.result.cpm / currentTarget.population) *
                  100
                : 0;
            break;
        }

        // give a message or process as a natural delivery for GRPs
        if (!grps) {
          messages.push(`${ZERO_COSTS_MSG} for "${vehicle.title}"`);
          handled = true;
          handleComplete();
        } else {
          // write unitcost to each daypart
          const unitCost = value / vehicle.dayparts.length;
          targets.forEach((target) => {
            spotplan.dayparts.forEach((daypart) => {
              daypart.result.addResults(0, target.id, { unitCost });
            });
          });

          // process as GRPs (natural delivery)
          event.columnDef = Column_GRPs.columnDef;
          value = grps;
        }
      }

      // COST entry
      if (costings.includes(event.columnDef)) {
        const metrics: Metrics = {
          costMethod: CostMethod[event.columnDef],
          costTargetId: currentTarget.id,
          [event.columnDef]: value,
        };

        const daypartMetrics: DaypartMetrics = {
          costMethod: CostMethod[event.columnDef],
          costTargetId: currentTarget.id,
          [event.columnDef]: value,
        };

        // get total base audience from dayparts
        const baseVehicle =
          this.mediaplannerService.plan.baseTarget.vehicleByMnemonic(
            vehicle.mnemonic
          );
        // sum all the daypart base audiences to get the total audience
        const baseAudience = baseVehicle.dayparts
          ? baseVehicle.dayparts
              .map((dp) => dp.audience)
              .reduce((tot, element) => tot + element, 0)
          : 0;

        targets.forEach((target) => {
          // write to the parent
          schedule.addResults(target, vehicle.id, metrics);
          schedule.addResults(target, vehicle.id, {
            type: ResultType.broadcast,
            reference: spotplan.id,
          });

          // write to the dayparts
          let unitCost = 0;
          let totalUnitCost = 0;
          const targetVehicle = target.vehicle(vehicle.id);
          const targetAudience = targetVehicle.dayparts
            .map((dp) => dp.audience)
            .reduce((tot, element) => tot + element, 0);

          // for each daypart, calcuate unit cost based on costMethod
          targetVehicle.dayparts.forEach((targetDaypart) => {
            unitCost = value;
            if (daypartMetrics.costMethod === CostMethod.cpm) {
              unitCost = targetDaypart.audience * value;
            }
            if (daypartMetrics.costMethod === CostMethod.baseCpm) {
              const baseDaypart = baseVehicle.dayparts.find(
                (dp) => dp.mnemonic === targetDaypart.mnemonic
              );
              unitCost = baseDaypart.audience * value;
            }

            totalUnitCost += unitCost;
            spotplan.addResults(targetDaypart.id, target.id, 0, daypartMetrics);
            spotplan.addResults(targetDaypart.id, target.id, 0, { unitCost });

            // if using baseCpm, record costmethod as cpm for each daypart so the costTargetId remains correct (Base tgt not necessarily in list)
            if (
              [CostMethod.baseCpm, CostMethod.cpm].includes(
                daypartMetrics.costMethod
              )
            ) {
              spotplan.addResults(targetDaypart.id, target.id, 0, {
                cpm: targetDaypart.audience
                  ? unitCost / targetDaypart.audience
                  : 0,
                costMethod: CostMethod.cpm,
                costTargetId: currentTarget.id,
              });
            }
          });

          // write the newly calculated costs back to the parent
          const cpm = targetAudience ? totalUnitCost / targetAudience : 0;
          const baseCpm = baseAudience ? totalUnitCost / baseAudience : 0;
          schedule.addResults(target, vehicle.id, { cpm, baseCpm });

          // if using baseCpm, record costmethod as cpm so the costTargetId remains correct (Base tgt not necessarily in list)
          if (
            [CostMethod.baseCpm, CostMethod.cpm].includes(metrics.costMethod)
          ) {
            schedule.addCosts(
              target,
              vehicle.id,
              cpm,
              CostMethod.cpm,
              currentTarget.id
            );
          }
        });

        handled = true;
        handleComplete();
      }

      // PLANNING entry:  impressions, GRPs, reach etc
      if (planning.includes(event.columnDef)) {
        // planning by impressions, get GRPs for natural delivery
        if (event.columnDef === Column_Impressions.columnDef) {
          value = currentTarget.population
            ? (value / currentTarget.population) * 100
            : 0;
        }
        handled = true;

        // planning by reach000, get Reach % for natural delivery
        if (event.columnDef === Column_Reach000.columnDef) {
          value = (value / currentTarget.population) * 100;
        }

        const [startWeek, endWeek] =
          week == -1 ? [0, spotplan.weekCount - 1] : [week, week];
        let vehicles: NaturalDeliveryVehicle[] = [];
        for (let w = startWeek; w <= endWeek; w++) {
          const daypartIds: NaturalDeliveryVehicle[] = vehicle.dayparts
            .filter((dp) => dp.audience > NATURAL_DELIVERY_MIN_AUDIENCE)
            .map((dp) => {
              return {
                id: `${dp.id}|${w}`,
                mnemonic: dp.mnemonic,
                audience: dp.audience,
                surveyCode:
                  vehicle?.survey?.code ||
                  this.mediaplannerService.plan.primarySurvey.code,
              };
            });

          vehicles = vehicles.concat(daypartIds);
        }

        const goal =
          [Column_ReachPct.columnDef, Column_Reach000.columnDef].indexOf(
            event.columnDef
          ) !== -1
            ? NaturalDeliveryGoalMetric.Reach
            : NaturalDeliveryGoalMetric.GRP;

        const surveyList = this.mediaplannerService.plan.surveyList;
        this.engineService
          .naturalDelivery(
            currentTarget.documentTarget,
            vehicles,
            goal,
            value,
            surveyList
          )
          .subscribe((data: NaturalDeliveryResponse) => {
            success = data.success;
            if (success) {
              reachRequired = true;
              spotplan.naturalDelivery.strategy =
                event.columnDef === Column_Impressions.columnDef
                  ? SpotplanStrategy.impressions
                  : event.columnDef === Column_GRPs.columnDef
                  ? SpotplanStrategy.GRPs
                  : event.columnDef === Column_Reach000.columnDef
                  ? SpotplanStrategy.reach000
                  : SpotplanStrategy.reachPct;

              data.results.forEach((dp) => {
                const [daypartId, w] = dp.id.split('|');
                targets.forEach((target) => {
                  spotplan.addResults(daypartId, target.id, parseInt(w), {
                    inserts: dp.numberOfInserts,
                  });
                });
              });

              // update parent vehicle itself
              const inserts = data.results
                .map((res) => res.numberOfInserts)
                .reduce((prev, cur) => prev + cur);
              targets.forEach((target) => {
                schedule.addResults(target, vehicle.id, {
                  inserts,
                  type: ResultType.broadcast,
                });
              });

              if (inserts === 0 && value > 0) {
                const currentSpotplan = schedule.spotplans.findSpotplan(
                  vehicle.id
                );
                schedule.spotplans.deleteSpotplan(currentSpotplan.id);
                messages.push(ZERO_INSERTS_MSG);
              }

              if (value === 0) {
                const currentSpotplan = schedule.spotplans.findSpotplan(
                  vehicle.id
                );
                schedule.spotplans.deleteSpotplan(currentSpotplan.id);
              }
            } else {
              data.error ? messages.push(data.error) : null;
            }
            handleComplete();
          }); // naturalDelivery
      } // planning

      // EVERYTHING ELSE:  the column was not incostings or planning array
      if (!handled) {
        reachRequired = false;
        handleComplete();
      }
    });
  }

  // process inputs specifically from the spot-step component
  public processSpotplanSchedule(
    target: Target,
    targets: Target[],
    schedule: Schedule,
    spotplan: SpotplanSchedule,
    dayparts: SpotplanScheduleDaypart[],
    columnDef: string,
    week: number,
    value: number
  ): Observable<ProcessInputResponse> {
    // Helper functions private to and only used by processSpotplanSchedule

    // calculate total inserts for all targets, all weeks, all dayparts
    // called after spots have been allocated to the dayparts
    const updateTotalSpots = () => {
      targets.forEach((target) => {
        let totalSpots = 0;
        const vehicle = schedule.vehicle(target, spotplan.vehicleId);
        spotplan.dayparts.forEach((dp) => {
          for (let w = 0; w <= spotplan.weekCount - 1; w++) {
            totalSpots += dp.result.result(w, target.id)?.inserts || 0;
          }
        });
        vehicle.result.addResults({ inserts: totalSpots });
      });
    };

    // Assign spots based on GRPs or impressions when there's only a single daypart. A shortcut to natural delivery
    const processSingleDaypart = (
      vehicle: TargetVehicle,
      columnDef: string,
      startWeek: number,
      endWeek: number
    ) => {
      const multiplier =
        columnDef === Spot_Columns.Column_GRPs.columnDef
          ? 100
          : target.population;
      const audience = target.population
        ? vehicle.dayparts.find((dp) => dp.id === dayparts[0].id).audience /
          target.population
        : 0;
      const daypart = spotplan.daypart(dayparts[0].id);
      const inserts = audience ? Math.ceil(value / (audience * multiplier)) : 0;
      targets.forEach((target) => {
        for (let w = startWeek; w <= endWeek; w++) {
          daypart.result.addResults(w, target.id, { inserts });
          if (inserts === 0)
            daypart.result.addResults(w, target.id, EMPTY_DAYPART_RESULTS);
        }
      });
    };

    // ZERO inserts and results for all dayparts being passed to the natural delivery function
    const clearDaypartsForNaturalDelivery = (
      startWeek: number,
      endWeek: number
    ) => {
      targets.forEach((target) => {
        dayparts.forEach((daypart) => {
          for (let w = startWeek; w <= endWeek; w++) {
            daypart.result.addResults(w, target.id, EMPTY_DAYPART_RESULTS);
          }
        });
      });
    };

    // complete the observable and return results
    const handleComplete = (
      observable: Subscriber<ProcessInputResponse>,
      success: boolean,
      messages: string[]
    ) => {
      updateTotalSpots();
      observable.next({ success, reachRequired: success, messages });
      observable.complete();
    };

    // LET IT BEGIN!
    return new Observable((observable) => {
      const vehicle = target.vehicle(spotplan.vehicleId);

      // if weeks === -1, process all weeks
      const [startWeek, endWeek] =
        week === -1 ? [0, spotplan.weekCount - 1] : [week, week];

      switch (columnDef) {
        // SPOTS INPUT: no natural delivery possible, just keep allocating until the total spots is met
        case Spot_Columns.Column_Spots.columnDef:
          const spotsPerDaypart = Math.ceil(value / dayparts.length);
          let inserts = 0;

          targets.forEach((target) => {
            let remaining = value;
            dayparts.forEach((daypart) => {
              for (let w = startWeek; w <= endWeek; w++) {
                inserts =
                  remaining < spotsPerDaypart ? remaining : spotsPerDaypart;
                remaining = Math.max(0, remaining - spotsPerDaypart);

                daypart.result.addResults(w, target.id, { inserts });
                if (inserts === 0)
                  daypart.result.addResults(
                    w,
                    target.id,
                    EMPTY_DAYPART_RESULTS
                  );
              }
            });
          });

          handleComplete(observable, true, []);
          break;

        // GRP INPUT
        case Spot_Columns.Column_GRPs.columnDef:
          // single daypart entry, convert to inserts by hand
          if (dayparts.length === 1) {
            processSingleDaypart(
              vehicle,
              Spot_Columns.Column_GRPs.columnDef,
              startWeek,
              endWeek
            );
            handleComplete(observable, true, []);
          } else {
            // multiple dayparts, use natural delivery
            clearDaypartsForNaturalDelivery(startWeek, endWeek);
            this.naturalDelivery(
              [vehicle],
              target,
              targets,
              schedule,
              NaturalDeliveryGoalMetric.GRP,
              value,
              dayparts,
              week
            ).subscribe((res) => {
              handleComplete(observable, res.success, [res.message]);
            });
          }
          break;

        // IMPRESSIONS INPUT
        case Spot_Columns.Column_Impressions.columnDef:
          // single daypart entry, convert to inserts by hand
          if (dayparts.length === 1) {
            processSingleDaypart(
              vehicle,
              Spot_Columns.Column_Impressions.columnDef,
              startWeek,
              endWeek
            );
            handleComplete(observable, true, []);
          } else {
            // multiple dayparts, use natural delivery
            clearDaypartsForNaturalDelivery(startWeek, endWeek);
            this.naturalDelivery(
              [vehicle],
              target,
              targets,
              schedule,
              NaturalDeliveryGoalMetric.Impressions,
              value,
              dayparts,
              week
            ).subscribe((res) => {
              handleComplete(observable, res.success, [res.message]);
            });
          }
          break;

        // REACH 000 and PERCENT INPUT
        case Spot_Columns.Column_Reach000.columnDef:
        case Spot_Columns.Column_ReachPct.columnDef:
          value =
            columnDef === Spot_Columns.Column_Reach000.columnDef
              ? (value / target.population) * 100
              : value;

          clearDaypartsForNaturalDelivery(startWeek, endWeek);
          this.naturalDelivery(
            [vehicle],
            target,
            targets,
            schedule,
            NaturalDeliveryGoalMetric.Reach,
            value,
            dayparts,
            week
          ).subscribe((res) => {
            handleComplete(observable, res.success, [res.message]);
          });
          break;
      }
    });
  }

  public processTotalsRow(
    event: TreeTableEditEvent,
    currentTarget: Target,
    targets: Target[],
    schedule: Schedule,
    value: number
  ): Observable<ProcessInputResponse> {
    return new Observable((observable) => {
      const rowContents: RowContents = event.row.data.contents;

      const vehicles = currentTarget.vehicles.filter((vehicle) =>
        rowContents.type === RowType.scheduleTotal
          ? vehicle
          : vehicle.mediaType === rowContents.id
      );

      let goal: NaturalDeliveryGoalMetric;

      switch (event.columnDef) {
        case Column_GRPs.columnDef:
          goal = NaturalDeliveryGoalMetric.GRP;
          break;

        case Column_Impressions.columnDef:
          goal = NaturalDeliveryGoalMetric.Impressions;
          break;

        case Column_Reach000.columnDef:
          goal = NaturalDeliveryGoalMetric.Reach;
          value = currentTarget.population
            ? (value / currentTarget.population) * 100
            : 0;
          break;

        case Column_ReachPct.columnDef:
          goal = NaturalDeliveryGoalMetric.Reach;
          break;

        default:
          break;
      }

      this.naturalDelivery(
        vehicles,
        currentTarget,
        targets,
        schedule,
        goal,
        value
      ).subscribe((response: NaturalDeliveryResponseMessage) => {
        const result: ProcessInputResponse = {
          success: response.success,
          reachRequired: response.success,
          messages: response.message ? [response.message] : [],
        };
        observable.next(result);
        observable.complete();
      });
    });
  }

  /**
   * Natural Delivery by reach on the supplied criteria
   *
   * @param vehicles The document type, i.e. InsightReport or Target
   * @param target target to base the delivery on
   * @param targets all targets to copy results to
   * @param scheule to populate
   * @param reach to apply to populate
   * @returns Observable<success>
   */

  private naturalDelivery(
    vehicles: TargetVehicle[],
    target: Target,
    targets: Target[],
    schedule: Schedule,
    goal: NaturalDeliveryGoalMetric,
    value: number,
    dayparts: SpotplanScheduleDaypart[] = [],
    week: number = -1
  ): Observable<NaturalDeliveryResponseMessage> {
    return new Observable((observable) => {
      let naturalVehicles: NaturalDeliveryVehicle[] = [];

      // build vehicle list to be processed for natural delivery
      vehicles.forEach((vehicle: TargetVehicle) => {
        if (vehicle.dayparts?.length) {
          const spotplan = schedule.spotplans.addSpotplan(
            target.vehicle(vehicle.id)
          );

          const [startWeek, endWeek] =
            week === -1 ? [0, spotplan.weekCount - 1] : [week, week];
          let filteredDayparts: TargetDaypart[] = [];
          if (dayparts.length) {
            const ids = dayparts.map((d) => d.id);
            filteredDayparts = vehicle.dayparts.filter(
              (dp) =>
                ids.includes(dp.id) &&
                dp.audience > NATURAL_DELIVERY_MIN_AUDIENCE
            );
          } else {
            filteredDayparts = vehicle.dayparts.filter(
              (dp) => dp.audience > NATURAL_DELIVERY_MIN_AUDIENCE
            );
          }

          for (let w = startWeek; w <= endWeek; w++) {
            const dps: NaturalDeliveryVehicle[] = filteredDayparts.map((dp) => {
              return {
                id: `${dp.id}|${w}`,
                mnemonic: dp.mnemonic,
                audience: dp.audience,
                reference: vehicle.id, // track parent vehicle
                surveyCode:
                  vehicle?.survey?.code ||
                  this.mediaplannerService.plan.currentSurvey.code,
              };
            });
            naturalVehicles = naturalVehicles.concat(dps);
          }
        } else {
          // vehicles loaded via multi survey are exclued from natural delivery
          if (!vehicle.isMultiSurvey) {
            naturalVehicles.push({
              id: vehicle.id,
              mnemonic: vehicle.mnemonic,
              audience: vehicle.audience,
              surveyCode:
                vehicle?.survey?.code ||
                this.mediaplannerService.plan.primarySurvey.code,
            });
          }
        }
      });

      // zero all the results for the naturalVehicles that were preapred for the natural delivery call.
      if (value === 0) {
        targets.forEach((target) => {
          naturalVehicles.forEach((result) => {
            // is a broadcast, clear the dayparts
            if (result.reference) {
              const spotplan = schedule.spotplans.addSpotplan(
                target.vehicle(result.reference)
              );

              const [startWeek, endWeek] =
                week == -1 ? [0, spotplan.weekCount - 1] : [week, week];
              for (let w = startWeek; w <= endWeek; w++) {
                spotplan.addResults(
                  result.id,
                  target.id,
                  w,
                  EMPTY_DAYPART_RESULTS
                );
              }

              schedule.addResults(target, result.reference, {
                inserts: 0,
                duration: 1,
                type: ResultType.broadcast,
              });
            } else {
              // zero a regular vehicle
              schedule.addResults(target, result.id, {
                inserts: 0,
                duration: 1,
                type: ResultType.vehicle,
                reference: result.reference,
              });
            }
          });
        });
        observable.next({ success: true, message: '' });
        observable.complete();
      } else {
        const surveyList = this.mediaplannerService.plan.surveyList;
        // perform natural delivery
        this.engineService
          .naturalDelivery(
            target.documentTarget,
            naturalVehicles,
            goal,
            value,
            surveyList
          )
          .subscribe((data: NaturalDeliveryResponse) => {
            const response: NaturalDeliveryResponseMessage = {
              success: data.success,
              message: data.error || '',
            };

            if (data.success) {
              targets.forEach((target) => {
                data.results.forEach((result) => {
                  // determine if vehicle is a daypart or not when populating results object
                  const naturalVehicle = naturalVehicles.find(
                    (v) => v.id === result.id
                  );

                  let added = false;
                  // if broadcast, write results to a spotplan
                  if (naturalVehicle.reference) {
                    const spotplan = schedule.spotplans.addSpotplan(
                      target.vehicle(naturalVehicle.reference)
                    );

                    spotplan.naturalDelivery.strategy =
                      goal === NaturalDeliveryGoalMetric.GRP
                        ? SpotplanStrategy.GRPs
                        : goal === NaturalDeliveryGoalMetric.Impressions
                        ? SpotplanStrategy.impressions
                        : SpotplanStrategy.reach000;

                    const [daypartId, w] = result.id.split('|');
                    spotplan.addResults(daypartId, target.id, parseInt(w), {
                      inserts: result.numberOfInserts,
                    });
                    added = true;
                  }

                  if (!added) {
                    schedule.addResults(target, result.id, {
                      inserts: result.numberOfInserts,
                      duration: result.duration,
                      type: ResultType.vehicle,
                      reference: naturalVehicle.reference,
                    });
                  }
                });

                // write the spotcounts to each of the broadcast parents for this target
                // also populating the weekly results
                const broadcastVehicles = vehicles.filter(
                  (veh) => veh.dayparts && veh.dayparts.length
                );

                // any broadcast to process?
                broadcastVehicles.forEach((broadcastVehicle) => {
                  const spotplan =
                    schedule.spotplans.addSpotplan(broadcastVehicle);
                  const [startWeek, endWeek] =
                    week === -1 ? [0, spotplan.weekCount - 1] : [week, week];

                  for (let w = startWeek; w <= endWeek; w++) {
                    spotplan.setWeekTotal(w, target.id, {
                      inserts: spotplan.calculateInserts(w, -1, target.id),
                    });
                  }

                  schedule.addResults(target, broadcastVehicle.id, {
                    inserts: spotplan.calculateInserts(week, -1, target.id), // week -1 means whole week
                    type: ResultType.broadcast,
                  });
                });
              }); // targets
            } // if success

            observable.next(response);
            observable.complete();
          });
      }
    });
  }

  clearCostTotals(schedule: Schedule, targets: Target[]) {
    this.engineService.clearCostTotals(schedule, targets);
  }

  evaluateAllSchedules(
    targets: Target[],
    schedules: Schedule[],
    vehicleGroups: VehicleGroup[],
    includeUniqueReach: boolean,
    includefrequency: boolean,
    vehicles: ScheduleVehicle[] = null,
    multiSurveyManualInput: ManualInputVehicleRequest = null
  ): Observable<boolean[]> {
    const frequencyLevel = includefrequency
      ? this.mediaplannerService.plan.freqDistributionSettings.freqLevelTo
      : 0;
    const surveyList: SurveyInfo[] =
      this.mediaplannerService.plan.surveyListForMultiSurvey;
    return this.engineService.evaluateMediaPlanAllSchedules(
      targets,
      schedules,
      vehicleGroups,
      includeUniqueReach,
      vehicles,
      this.mediaplannerService.plan.effectiveReach,
      frequencyLevel,
      this.mediaplannerService.plan.multiSurveys.multiSurveys,
      multiSurveyManualInput,
      surveyList
    );
  }

  // evaluate each target mediaplan and combine into one observable
  evaluate(
    targets: Target[],
    schedule: Schedule,
    vehicleGroups: VehicleGroup[],
    includeUniqueReach: boolean,
    includeFrequency: boolean,
    vehicles: ScheduleVehicle[] = null,
    multiSurveyManualInput: ManualInputVehicleRequest = null
  ): Observable<boolean> {
    const frequencyLevel = includeFrequency
      ? this.mediaplannerService.plan.freqDistributionSettings.freqLevelTo
      : 0;

    const surveyList: SurveyInfo[] =
      this.mediaplannerService.plan.surveyListForMultiSurvey;

    return this.engineService.evaluateMediaPlan(
      targets,
      schedule,
      vehicleGroups,
      includeUniqueReach,
      vehicles,
      this.mediaplannerService.plan.effectiveReach,
      frequencyLevel,
      this.mediaplannerService.plan.multiSurveys.multiSurveys,
      multiSurveyManualInput,
      surveyList
    );
  }
}
