import { ScheduleTotal } from '../classes/schedule-total';
import { TargetStatus } from '../classes/target';
import { ScheduleVehicle, TargetVehicle } from '../classes/vehicle';
import { TargetStatement } from './engine.models';
import { VehicleGroupVehicle } from '../classes/vehicle-groups';
import { EffectiveReach, MediaSettings } from '../classes/media-planner';
import { SpotplanNaturalDelivery } from '../classes/spotplan-schedule';
import { ChartSettings } from './charts.model';
import { DaypartMetrics } from '../classes/daypart-result';
import { MultiSurvey } from '../classes/multi-survey';
import { Operator } from './visual-code-builder.models';

const TARGET_VERSION: number = 1;

export const UNSAVED_DOCUMENT = 'unsaved';
export const APPLICATION_NAME = 'Plan';
export const DOCUMENT_CAMPAIGN_VERSION: number = 1;
// version 1:  targets, baseTargets, schedules and multiSchedules are compressed

export const COMPRESS_CAMPAIGNS: boolean = true;

export enum DocumentOrigin {
  normal,
  autosaved,
  template,
  upload,
}

export interface DocumentBrief {
  campaignName: string;
  advertiserName: string;
  plannerName: string;
  startDate: string;
  campaignId: string;

  briefObjective: string;
  KPI1: string;
  KPI2: string;
  KPI3: string;
  flightDateStart: string;
  flightDateEnd: string;
  budget: string;
  constraints: string;
  comments: string;
}

export interface DocumentFullSurvey extends DocumentSurvey {
  provider: string;
  language: string;
  year: number;
  isMultibased: boolean;
  isTrendable: boolean;
  isMb3Enabled: boolean;
  isMappable: boolean;
  hasVehicles: boolean;
  surveyInfo: string;
  copyrightInfo: string;
  isMrfGlobaldemomap: boolean;
  isMrfProfile: boolean;
  isPrimary: boolean;
  isCurrent: boolean;
  occurrenceCount?: number;
  isFullAccess?: boolean;
  checked?: boolean;
  meta?: {
    [key: string]: unknown;
  };
  mediaTypes?: string;
}

// full info loaded about a survey
// populated from codebookService.getSurvey()
export interface DocumentSurvey {
  code: string;
  title: string;
  authorizationGroup: string;
  authorizationGroups?: string[];
  mediaDisabled?: boolean;
  esgProviders: string[];
  population: number;
  sample: number;
  unitsText: string;
  units: number;
}

export const EMPTY_SURVEY: DocumentSurvey = {
  code: '',
  title: '',
  authorizationGroup: '',
  esgProviders: [],
  population: 0,
  sample: 0,
  unitsText: '',
  units: 0,
};

// document storage Target Object
export interface DocumentTarget {
  fileVersion?: number;
  survey?: DocumentSurvey;

  title?: string;
  ownTitle?: string;
  shortTitle?: string;
  coding?: string;
  manual?: boolean;
  jsonCoding?: TargetStatement;
  addressableTarget?: boolean;
  planningTarget?: boolean;
  isMultiSurvey?: boolean;

  operator?: Operator;

  population?: number;
  sample?: number;

  targets?: DocumentTarget[];
  customPopulation?: number; // Basic target population updated with DAU/custom population
  ownPopulation?: number; // store original audience pop when custom pop is added, because population gets re-written
}

// Campaign header for doc storage
export interface DocumentCampaignHeader {
  version: number;
  GRPs: number;
  reach: number;
  frequency: number;
  targetCount: number;
  targetNames: string;
  mediaTypes: string;
  survey: string;
  surveyCode: string;
  description: string;
  unitsText: string;
  units: number;
  campaignStage: string;
}

export interface DocumentTargetResults {
  id: string;
  status: TargetStatus;
  documentTarget: DocumentTarget;
  vehicles: TargetVehicle[];
}

export interface DocumentSchedule {
  name: string;
  vehicles: ScheduleVehicle[];
  scheduleTotal: ScheduleTotal[];
  spotplans: DocumentSpotplanSchedule[];
}

export interface DocumentSpotplanSchedule {
  id: string;
  name: string;
  vehicleId: string;
  naturalDelivery: SpotplanNaturalDelivery;
  dayparts: any[];

  allocation: number;
  weeks: DaypartMetrics[];
  days: DaypartMetrics[];
  weekCount: number;
}

export interface DocumentVehicleGroup {
  id: String;
  name: string;
  vehicles: VehicleGroupVehicle[];
}

// main mediaplanner project file holding all needed for full plan
export class DocumentCampaign {
  brief: DocumentBrief;
  survey: DocumentFullSurvey;
  selectedSurveys: DocumentFullSurvey[];
  header: DocumentCampaignHeader;
  baseTarget: DocumentTargetResults | string[];
  targets: DocumentTargetResults[] | string[];
  schedules: DocumentSchedule[] | string[];
  vehicleGroups: DocumentVehicleGroup[];
  columns: DocumentColumns;
  freqDistributionSettings: FrequencySettings;
  effectiveReach: EffectiveReach;
  mediaSettings: MediaSettings;
  chartSettings: { [key: string]: ChartSettings };
  creatorApp?: string;
  multiSurveys?: MultiSurvey[] | string[];
}

export interface DocumentMultiSurveys {
  multiSurveys: MultiSurvey[];
}

export interface DocumentColumns {
  visibleColumns: string[];
  frequencyDistributionColumns: string[];
}

export interface FrequencySettings {
  freqLevelTo: number;
  isGroupingOn: boolean;
  freqUpTo: number;
  groupsOf: number;
}
