import {
  Component,
  Input,
  ViewChild,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  NodeMenuClickEvent,
  TreeTableColumn,
  TreeTableMenuItem,
  TreeTableNode,
} from '../tree-table/tree-table.models';
import { Target } from 'src/app/classes/target';
import { MediaPlannerService } from 'src/app/services/media-planner.service';
import {
  SCH_DELIMITER,
  Schedule,
  TOTAL_SCHEDULE_NAME,
} from 'src/app/classes/schedule';
import { TreeTableComponent } from '../tree-table/tree-table.component';
import { ScheduleTotalTag } from 'src/app/classes/schedule-total';
import {
  MEDIATYPE_ALL,
  MediatypeView,
} from 'src/app/steps/planning-step/planning-step.component';
import { PlanningValueProviderService } from 'src/app/services/planning-value-provider.service';
import { VehiclePlanningData } from 'src/app/classes/planning-data';
import { DialogService } from 'src/app/services/dialog.service';
import { EngineService } from 'src/app/services/engine.service';
import { compareOptionKeys } from '../../models/planning.models';
import { AppendUnitsPipe } from 'src/app/pipes/append-units.pipe';
import { ScheduleVehicle } from 'src/app/classes/vehicle';

@Component({
  selector: 'compare-plans',
  templateUrl: './compare-plans.component.html',
  styleUrls: ['./compare-plans.component.scss'],
})
export class ComparePlansComponent implements OnInit {
  @Input() currentTarget: Target;
  @Input() selectedSchedules: Schedule[] = [];
  @Input() unitsText: string;

  @Output() scheduleTotalUpdated: EventEmitter<Schedule> =
    new EventEmitter<Schedule>();

  @ViewChild('compareByAudiencesTable')
  compareByAudiencesTable: TreeTableComponent;

  _processing: boolean;
  @Input() set processing(value: boolean) {
    this._processing = value;
  }
  get processing(): boolean {
    return this._processing;
  }

  get targets(): Target[] {
    return this.mediaplannerService.plan.targets.filter(
      (target) => target.planningTarget
    );
  }

  vehicleColumns: TreeTableColumn[] = [];

  totalsData: { [target: string]: VehiclePlanningData } = {};

  targetsInlineMenu: TreeTableMenuItem[] = [
    { label: 'Add/remove columns', data: 'add|remove', matIcon: 'view_week' },
  ];

  tableColumnsNumber: number = 0;

  mediaTypes: MediatypeView[] = [];

  totalRows = [];

  tableData: TreeTableNode[] = [];
  tableColumns: TreeTableColumn[] = [];
  totalsSchedule = new Schedule(TOTAL_SCHEDULE_NAME);

  appendUnits = new AppendUnitsPipe();

  constructor(
    private mediaplannerService: MediaPlannerService,
    private planningValueProviderService: PlanningValueProviderService,
    private dialogService: DialogService,
    private engineService: EngineService
  ) {}

  ngOnInit(): void {
    //add vehicles to totals schedule
    this.prepareTotalsSchedule();

    this.vehicleColumns = this.mediaplannerService.plan.columns.allColumns;
    this.mediaplannerService.plan.columns
      .getVisibleColumns()
      .forEach((column) => {
        this.totalRows.push({
          label: this.appendUnits.transform(column, this.unitsText),
          rowDef: column.columnDef,
        });
      });
    this.loadData();
  }

  populateWithDayparts(
    target: Target,
    vehicle: ScheduleVehicle,
    vehicleId: string,
    schedule: Schedule,
    scheduleIndex: number
  ) {
    const targetVehicle = target.vehicle(vehicle.vehicleId);

    // this is a broadcast vehicle so record all the dayparts across all weeks
    if (targetVehicle.dayparts && targetVehicle.dayparts.length) {
      const scheduleSpotplan = schedule.spotplans.findSpotplan(
        targetVehicle.id
      ); // get spotplan we're going to copy
      if (scheduleSpotplan) {
        const totalSpotplan = this.totalsSchedule.spotplans.addSpotplan(
          targetVehicle,
          null,
          `${SCH_DELIMITER}${scheduleIndex}`
        ); // create a spotplan inc. the scheduleIndex
        totalSpotplan.weekCount = scheduleSpotplan.weekCount;

        for (let w = 0; w < scheduleSpotplan.weekCount; w++) {
          // copy each of its weeks
          scheduleSpotplan.dayparts.forEach((daypart) => {
            // this daypart may not have numbers for the given week
            const daypartResult = daypart.result.result(w, target.id);
            daypartResult
              ? totalSpotplan.addResults(
                  `${daypart.id}${SCH_DELIMITER}${scheduleIndex}`, // indexed by schedule
                  target.id,
                  w,
                  daypartResult
                )
              : null;
          });
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedSchedules = changes['selectedSchedules'];

    if (
      selectedSchedules &&
      selectedSchedules.currentValue !== selectedSchedules.previousValue &&
      !selectedSchedules.firstChange
    ) {
      this.prepareTotalsSchedule();

      setTimeout(() => {
        this.processing = true;
        this.engineService
          .evaluateMediaPlan(
            this.mediaplannerService.plan.targets,
            this.totalsSchedule,
            this.mediaplannerService.plan.vehicleGroups.groups,
            this.mediaplannerService.plan.columns.includeUniqueReach,
            null,
            this.mediaplannerService.plan.effectiveReach,
            null,
            this.mediaplannerService.plan.multiSurveys.multiSurveys,
            null,
            this.mediaplannerService.plan.surveyList
          )
          .subscribe((success) => {
            if (success) {
              this.processing = false;
              this.loadData();
              this.addTotalsData();
            }
          });
      });
    }

    const currentTarget = changes['currentTarget'];
    if (
      currentTarget &&
      currentTarget.currentValue !== currentTarget.previousValue
    ) {
      this.loadData();
      this.addTotalsData();
    }
  }

  private loadData() {
    this.mediaTypes = [];
    this.tableData = [];
    this.tableColumns = [];
    this.totalsData = {};

    this.mediaTypes = this.buildMediaTypeList();
    this.buildTableColumns();
    this.tableColumnsNumber = this.tableColumns.length + 1;
    this.generateTotalsData();
  }

  // add vehicles of each selected schedule from both targets
  private prepareTotalsSchedule() {
    this.totalsSchedule.clearAll();
    this.targets.forEach((target) => {
      const mediatypes: { [mediaType: string]: number } = {};
      mediatypes['total'] = 0;

      this.selectedSchedules.forEach((schedule, scheduleIndex) => {
        schedule.vehicles.forEach((schVeh) => {
          const totSchVeh = this.totalsSchedule.vehicle(
            target,
            `${schVeh.vehicleId}${SCH_DELIMITER}${scheduleIndex}`
          );
          this.totalsSchedule.addResults(
            target,
            totSchVeh.vehicleId,
            schVeh.result
          );

          // keep a tally of inserts by media type
          const vehicleTarget = target.vehicle(schVeh.vehicleId);
          mediatypes[vehicleTarget.mediaType] =
            mediatypes[vehicleTarget.mediaType] || 0;
          mediatypes[vehicleTarget.mediaType] += schVeh.result.inserts;
          mediatypes['total'] += schVeh.result.inserts;

          // check if there are dayparts then copy if neeeded
          this.populateWithDayparts(
            target,
            schVeh,
            totSchVeh.vehicleId,
            schedule,
            scheduleIndex
          );
        });
      });

      // write insert totals back to each of the groups
      Object.keys(mediatypes).forEach((mediatype) => {
        const totalMediaType = this.totalsSchedule.getScheduleTotal(
          mediatype,
          mediatype === 'total'
            ? ScheduleTotalTag.total
            : ScheduleTotalTag.mediatype,
          target
        );
        totalMediaType.result.addResults({ inserts: mediatypes[mediatype] });
      });
    });
  }

  private addTotalsData() {
    if (this.tableColumns.length <= 1) {
      return;
    }

    const { id: targetId, vehicles } = this.currentTarget;

    this.tableData.forEach((mediaType) => {
      const groupName = mediaType.name === 'Total' ? 'total' : mediaType.name;
      const scheduleTotalResult = this.totalsSchedule.scheduleTotal.find(
        (scheduleTotal) =>
          scheduleTotal.group === groupName &&
          scheduleTotal.targetId === targetId
      )?.result;

      mediaType.children.forEach((child) => {
        if (child.name !== 'Per Media Vehicle') {
          child.data[TOTAL_SCHEDULE_NAME] =
            scheduleTotalResult?.[child.def] ?? 0;
          child.data = this.planningValueProviderService.getFormattedCellString(
            child.data as any,
            this.tableColumns
          );
        }

        if (child.name === 'Per Media Vehicle') {
          child.children.forEach((vehicle) => {
            const currentVehicleId = vehicles.find(
              (targetVehicle) => targetVehicle.originalTitle === vehicle.name
            )?.id;

            const result = this.totalsSchedule.scheduleTotal.find(
              (group) =>
                group.group === currentVehicleId && group.targetId === targetId
            )?.result;

            vehicle.children.forEach((vehicleChild) => {
              vehicleChild.data[TOTAL_SCHEDULE_NAME] =
                result?.[vehicleChild.def] ?? 0;
              vehicleChild.data =
                this.planningValueProviderService.getFormattedCellString(
                  vehicleChild.data as any,
                  this.tableColumns
                );
            });
          });
        }
      });
    });
    this.scheduleTotalUpdated.emit(this.totalsSchedule);
  }

  private buildMediaTypeList(): MediatypeView[] {
    const medTypes: MediatypeView[] = [];
    this.currentTarget.vehicles.forEach((vehicle) => {
      if (!medTypes.find((med) => med.name === vehicle.mediaType))
        medTypes.push({
          name: vehicle.mediaType,
          isMultiSurvey: vehicle.isMultiSurvey || false,
        });
    });
    return medTypes;
  }

  buildTableColumns() {
    if (this.selectedSchedules.length) {
      this.selectedSchedules.forEach((schedule, index) => {
        const targetColumn: TreeTableColumn = {
          header: schedule.name,
          columnDef: `${schedule.name}_${index}`,
          columnType: 'number',
          cell: (row) => {
            return row?.data?.hasOwnProperty(`${schedule.name}_${index}`)
              ? row.data[`${schedule.name}_${index}`]
              : '';
          },
        };
        this.tableColumns.push(targetColumn);
      });

      //add total columns in the table if needed
      if (
        this.tableColumns.length > 1 &&
        !this.tableColumns.some(
          (column) => column.columnDef === TOTAL_SCHEDULE_NAME
        )
      ) {
        this.tableColumns.push({
          header: 'Total',
          columnDef: TOTAL_SCHEDULE_NAME,
          columnType: 'number',
          cell: (row) => {
            return row?.data?.hasOwnProperty(TOTAL_SCHEDULE_NAME)
              ? row.data[TOTAL_SCHEDULE_NAME]
              : '';
          },
        });
      }
    }
  }

  generateTotalsData() {
    const totalsData: TreeTableNode[] = [];

    const createChildrenData = () => {
      return this.totalRows.map((row) => ({
        name: row.label,
        def: row.rowDef,
        data: {},
      }));
    };

    totalsData.push({
      name: 'Total',
      id: totalsData.length,
      css: 'total-table-row',
      rowCss: 'table-total-row',
      expanded: true,
      children: [],
      skipSort: true,
    });

    const totalsChildrenList = createChildrenData();

    this.selectedSchedules.forEach((schedule, index) => {
      const valueData = this.mediaplannerService.plan.getTotalsPlanningData(
        'total',
        ScheduleTotalTag.total,
        this.currentTarget,
        schedule
      );

      const data = this.planningValueProviderService.getFormattedCellString(
        valueData as any,
        this.vehicleColumns
      );

      totalsChildrenList.forEach((children) => {
        children.data[`${schedule.name}_${index}`] = data[children.def];
      });
    });
    totalsData[totalsData.length - 1].children.push(...totalsChildrenList);

    // create objects for each media type
    this.mediaTypes.forEach((mediaType) => {
      if (mediaType.name === MEDIATYPE_ALL) return;

      const multiSurveyRowCss = mediaType.isMultiSurvey
        ? 'multi-survey-title-row'
        : '';
      const mediaTypeNode = {
        name: mediaType.name,
        id: totalsData.length,
        rowCss: `table-title-row ${multiSurveyRowCss}`,
        expanded: true,
        children: [],
      };

      totalsData.push(mediaTypeNode);

      const mediaChildrenList = createChildrenData();

      this.selectedSchedules.forEach((schedule, index) => {
        const valueData = this.mediaplannerService.plan.getTotalsPlanningData(
          mediaType.name,
          [ScheduleTotalTag.mediatype, ScheduleTotalTag.multiSurvey],
          this.currentTarget,
          schedule
        );

        const data = this.planningValueProviderService.getFormattedCellString(
          valueData,
          this.vehicleColumns
        );

        mediaChildrenList.forEach((children) => {
          children.data[`${schedule.name}_${index}`] = data[children.def];
        });
      });

      const perMediaVehicleChildren = [];

      this.selectedSchedules.forEach((schedule, index) => {
        const vehiclesForCurrentTarget = this.currentTarget.vehicles.filter(
          (vehicle) => vehicle.mediaType === mediaType.name
        );

        vehiclesForCurrentTarget.forEach((vehicle, vehicleIndex) => {
          const vehicleValueData =
            this.mediaplannerService.plan.getVehiclePlanningData(
              this.currentTarget,
              schedule,
              vehicle
            );
          const vehicleData =
            this.planningValueProviderService.getFormattedCellString(
              vehicleValueData,
              this.vehicleColumns
            );

          const currentVehicleChildren = createChildrenData();
          currentVehicleChildren.forEach((vehicleChildren) => {
            vehicleChildren.data[`${schedule.name}_${index}`] =
              vehicleData[vehicleChildren.def];
          });

          //if first target push a new object else update existing object
          if (index === 0) {
            perMediaVehicleChildren.push({
              name: vehicle.title,
              id: perMediaVehicleChildren.length,
              expanded: true,
              children: [...currentVehicleChildren],
            });
          } else {
            perMediaVehicleChildren[vehicleIndex].children.forEach(
              (vehicleChildren) => {
                vehicleChildren.data[`${schedule.name}_${index}`] =
                  vehicleData[vehicleChildren.def];
              }
            );
          }
        });
      });

      mediaTypeNode.children.push(...mediaChildrenList, {
        name: 'Per Media Vehicle',
        id: mediaChildrenList.length,
        rowCss: 'table-title-per-vehicle',
        expanded: false,
        children: [...perMediaVehicleChildren],
      });
    });

    this.tableData = totalsData;
  }
  onTreeHeaderMenuClick(item: NodeMenuClickEvent) {
    if (item.item.data === 'add|remove') {
      this.showEditColumnsDialog();
    }
  }
  showEditColumnsDialog() {
    const [containsDirectMail, containsAddressable] =
      this.mediaplannerService.containsAddressableMedia(this.targets[0]);
    this.dialogService
      .openEditColumnsDialog({
        planColumnData: this.mediaplannerService.plan.columns,
        currentCompareOption: compareOptionKeys.plans,
        disabledGroups: {
          addressable: !containsAddressable,
          directMail: !containsDirectMail,
          esgScore: !this.currentTarget.survey.esgProviders?.length,
        },
      })
      .afterClosed()
      .subscribe((visibleColumns) => {
        if (visibleColumns) {
          this.totalRows = [];
          visibleColumns.columns.forEach((column) => {
            this.totalRows.push({
              label: column.header,
              rowDef: column.columnDef,
            });
          });
          this.loadData();
          this.addTotalsData();
        }
      });
  }
}
