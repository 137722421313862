import { findAllOccurences } from './arrayHelper';

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);
  R = parseInt((R * (100 + percent)) / 100 + '', 10);
  G = parseInt((G * (100 + percent)) / 100 + '', 10);
  B = parseInt((B * (100 + percent)) / 100 + '', 10);
  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;
  const RR =
    R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const checkColors = (colors, labelsNumber): string[] => {
  let restartIndex = colors.length;
  if (labelsNumber < colors.length) {
    colors = colors.slice(0, labelsNumber);
  }
  for (let color = 0; color < colors.length; color++) {
    if ((colors[color].match(/f/g) || []).length > 4) {
      restartIndex = color;
      break;
    }
  }
  colors = colors.slice(0, restartIndex);

  return colors;
};

export const provideColorsToCharts = (startColor, targetLength) => {
  let colors = [];
  for (let i = 0; i < 500; i = i + 25) {
    colors.push(shadeColor(startColor || '#0054ff', i));
  }
  colors = checkColors(colors, targetLength);
  colors = colorsArray(colors, targetLength);
  return colors;
};

export const provideColorsToHighlightValues = (startColor) => {
  const colorsArray: string[] = [];
  for (let i = 0.15; i < 0.6; i = i + 0.1) {
    colorsArray.push(hexToRGB(startColor, i.toString()));
  }
  return colorsArray;
};

export const colorsArray = (colors, targetLength) => {
  if (colors.length < targetLength) {
    const repetition = Math.ceil(targetLength / colors.length);

    for (let i = 0; i < repetition; i++) {
      colors.push(colors[i]);
    }
  }

  return colors;
};

// https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
export const hexToRGB = (hex: string, alpha: string = '0.5') => {
  const colorComponents = getRGBAcomponents(hex);

  return alpha !== '0'
    ? `rgba(${colorComponents[0]}, ${colorComponents[1]}, ${colorComponents[2]}, ${alpha})`
    : `rgb(${colorComponents[0]}, ${colorComponents[1]}, ${colorComponents[2]})`;
};

export const getRGBAcomponents = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
};

// https://css-tricks.com/converting-color-spaces-in-javascript/
export const rgbaToHex = (rgba: string) => {
  if (rgba.indexOf('rgba') === -1) {
    return rgba;
  }
  const color = rgba.substring(5);
  const commasIndexes = findAllOccurences(color, ',');

  let red = parseInt(color.substring(0, commasIndexes[0]), 10).toString(16);
  let green = parseInt(
    color.substring(commasIndexes[0] + 1, commasIndexes[1]),
    10
  ).toString(16);
  let blue = parseInt(
    color.substring(commasIndexes[1] + 1, commasIndexes[2]),
    10
  ).toString(16);

  if (red.length === 1) {
    red = '0' + red;
  }
  if (green.length === 1) {
    green = '0' + green;
  }
  if (blue.length === 1) {
    blue = '0' + blue;
  }

  return '#' + red + green + blue;
};
