import { LoadMultiSurveyResult, UploadFile } from '../models/file-upload-model';
import {
  EMPTY_MULTI_SURVEY,
  FileHeader,
  FileType,
  MULTI_SURVEY_DAU,
  MULTI_SURVEY_MRF,
  MultiSurveyTarget,
  MultiSurveyVehicle,
  MultiSurveyVehicleConfig,
  SurveyData,
} from '../models/multi-survey.models';
import { DauFile, DauTarget } from './dau-file';
import { MRF_PREFIX, MrfFile } from './mrf-file';
import { ScheduleVehicle, TargetVehicle } from './vehicle';
import { Result } from './result';
import { ScheduleTotal } from './schedule-total';
import uniqid from 'uniqid';
import { cloneDeep } from 'lodash';
import { DocumentFullSurvey } from '../models/document.model';
import { Target } from './target';

export class MultiSurveys {
  multiSurveys: MultiSurvey[] = []; // uploaded multi data files (MRF or DAU)
  selectedSurvey: DocumentFullSurvey; // survey selected on dashboard import

  /**
   * Load and create a valid MultiSurvey object from the passed in file contents and filename
   * If the load was successful then the multiSurveys will be added to, else the array untouched
   * the DAU or MRF object used to load and parse the file contains error and warning string arrays
   * which are passed back through LoadMultiSurveyResult
   *
   * @param {UploadFile} file Object containing the details for the file to concume
   * @returns {LoadMultiSurveyResult} Multi Survey Result object containing load status and the MultiSurvey object if loaded
   */

  load(file: UploadFile): LoadMultiSurveyResult {
    const fileExtension = file.fileName.split('.').pop().toLowerCase();
    const questionMarkPattern = /�/g;
    const fileContent = file.fileContent.replace(questionMarkPattern, '');
    const multiSurveyResult: LoadMultiSurveyResult = {
      success: false,
      multiSurveyFile: null,
    };

    switch (fileExtension) {
      case FileType.DAU:
        const dau = new DauFile();
        multiSurveyResult.success = dau.load(fileContent);
        if (multiSurveyResult.success) {
          multiSurveyResult.multiSurveyFile = this.add();
          multiSurveyResult.multiSurveyFile.fileType = fileExtension;
          multiSurveyResult.multiSurveyFile.dau = dau;
          multiSurveyResult.multiSurveyFile.header.planFilename = file.fileName;
        } else {
          multiSurveyResult.errors = dau.errors;
          multiSurveyResult.warnings = dau.warnings;
        }
        break;

      case FileType.MRF:
        const mrf = new MrfFile();
        multiSurveyResult.success = mrf.load(fileContent);
        if (multiSurveyResult.success) {
          multiSurveyResult.multiSurveyFile = this.add();
          multiSurveyResult.multiSurveyFile.fileType = FileType.MRF;
          multiSurveyResult.multiSurveyFile.mrf = mrf;
          multiSurveyResult.multiSurveyFile.header.planFilename = file.fileName;
        } else {
          multiSurveyResult.errors = mrf.errors;
          multiSurveyResult.warnings = mrf.warnings;
        }

        break;
    }
    return multiSurveyResult;
  }

  clearAll() {
    this.multiSurveys = [];
    this.selectedSurvey = null;
  }

  // receive the groups from the saved campaign and add to object arrays
  public loadCampaign(documentMultiSurveys: MultiSurvey[]) {
    documentMultiSurveys.forEach((docMultiSurvey) => {
      const item = this.add(
        docMultiSurvey.survey,
        docMultiSurvey.targets,
        docMultiSurvey.vehicles
      );
      item.fileType = docMultiSurvey.fileType;
      item.dau = docMultiSurvey.dau;
      item.mrf = docMultiSurvey.mrf;
      item.header = docMultiSurvey.header;
      item.id = docMultiSurvey.id;
      item.usedAsPlanAudience = docMultiSurvey.usedAsPlanAudience;
      item.distribution = docMultiSurvey.distribution;
      item.keepThousands = docMultiSurvey.keepThousands;
    });
  }

  private add(
    survey?: SurveyData,
    targets?: MultiSurveyTarget[],
    vehicles?: MultiSurveyVehicle[]
  ): MultiSurvey {
    this.multiSurveys.unshift(new MultiSurvey(survey, targets, vehicles));
    return this.multiSurveys[0];
  }

  remove(index: number = null) {
    if (index !== null) {
      this.multiSurveys.splice(index, 1);
    } else {
      this.multiSurveys.shift();
    }
  }

  // will return the multi survey with the given multiSurveyId
  multiSurvey(multiSurveyId: string) {
    const multiSurveyItem = this.multiSurveys.find(
      (multiSurvey) => multiSurvey.id === multiSurveyId
    );

    return multiSurveyItem || null;
  }

  // select the correct target in each Multi survey file while de-selecting all the others
  selectTargetVehicles(selectedTargetPerFile: { [fileIndex: number]: number }) {
    this.multiSurveys.forEach((multiSurvey, index) => {
      multiSurvey.selectTargetVehicles(
        selectedTargetPerFile[index] !== undefined
          ? selectedTargetPerFile[index]
          : -1
      );
    });
  }

  selectPlanAudience(targetAudienceFileId: string) {
    this.multiSurveys.forEach((multiSurvey, index) => {
      multiSurvey.selectPlanAudience(targetAudienceFileId === multiSurvey.id);
    });
  }

  setPreservedMetric(multiSurvey: MultiSurvey, keepThousands: boolean) {
    multiSurvey.keepThousands = keepThousands;
  }

  // return the selected target vehicles of the multi survey file used as plan audience
  getPlanAudience(): MultiSurveyTarget {
    const selectedMultiSurvey = this.multiSurveys.find(
      (multiSurvey) => multiSurvey.usedAsPlanAudience
    );

    return selectedMultiSurvey ? selectedMultiSurvey.targets[0] : null;
  }

  // return vehicles from uploaded DAU and MRF files, mapped as TargetVehicle
  getAllVehicles(): TargetVehicle[] {
    const vehicles: TargetVehicle[] = [];

    this.multiSurveys.forEach((multiSurvey) => {
      if (multiSurvey.targets.length) {
        const target = multiSurvey.targets[0];

        const prefix = multiSurvey.fileType === FileType.MRF ? MRF_PREFIX : '';
        const calculationMethod =
          multiSurvey.fileType === FileType.MRF
            ? MULTI_SURVEY_MRF
            : MULTI_SURVEY_DAU;

        const vehs: TargetVehicle[] = multiSurvey.vehicles.map((veh) => {
          const multiSurveyConfig: MultiSurveyVehicleConfig = {
            multiSurveyId: multiSurvey.id,
            vehicleRef: prefix ? veh.code : undefined,
            groupRef: prefix ? `mediatype|${veh.mediaType}` : undefined,
          };

          return {
            id: `${prefix}${veh.code}`,
            mnemonic: veh.code,
            mediaType: `${veh.mediaType} - ${multiSurvey.header.planFilename}`,
            mediaTypeId: veh.mediaTypeId,
            addressable: false,
            calculationMethod,
            isMediaGroup: false,
            title: veh.title,
            originalTitle: veh.title,
            audience: veh.audience * target.population,
            grossAudience: veh.audience * target.population,
            resps: 0, // ?
            potentialReach: 0, // ?
            compositionIndex: 0, // ?
            compositionPct: 0, // ?
            dayparts: [],
            ESG: [{ value: -1, Provider: '?' }],
            survey: EMPTY_MULTI_SURVEY,
            isMultiSurvey: true,
            multiSurveyConfig,
          };
        });

        vehicles.push(...vehs);
      }
    });
    return vehicles;
  }

  calculateTotals(
    scheduleVehs: ScheduleVehicle[],
    scheduleTotal: ScheduleTotal
  ) {
    let impressions = 0;
    let inserts = 0;
    let totalCost = 0;

    scheduleVehs.forEach((scheduleVeh) => {
      impressions += scheduleVeh.result.impressions || 0;
      inserts += scheduleVeh.result.inserts || 0;
      totalCost += scheduleVeh.result.unitCost || 0;
    });
    scheduleTotal.result.addResults({ inserts, impressions, totalCost });

    // locate which multiSurvey is active and calc the Total Reach from there (NB: won't work if muti target is allowed)
    const multiSurvey = this.multiSurveys.find(
      (survey) => survey.targets.length
    );
    if (multiSurvey && multiSurvey.distribution.length) {
      scheduleTotal.result.addResults({
        reach: 1 - multiSurvey.distribution[0],
      });
    }
  }

  getInfoDialogData(config: MultiSurveyVehicleConfig) {
    if (!config) return ['BAD MULTI SURVEY CONFIG OBJECT'];

    const infoDialogData: string[] = [];
    if (config.manualInput) {
      infoDialogData.push(`<strong>Source:</strong> Manually entered R&F`);
    } else {
      const multiSurveyFile = this.multiSurveys.find(
        (multiSurvey) => multiSurvey.id === config.multiSurveyId
      );
      infoDialogData.push(
        `<strong>Export source:</strong> ${
          multiSurveyFile[multiSurveyFile.fileType].product
        }`,
        `<strong>File name:</strong> ${multiSurveyFile.header.planFilename}`,
        `<strong>Survey:</strong> ${multiSurveyFile.survey.title}`
      );
      if (multiSurveyFile.fileType === FileType.DAU) {
        infoDialogData.push(
          `<strong>Number of targets:</strong> ${
            multiSurveyFile.getAllTargets().length
          }`,
          `<strong>Selected target:</strong> ${multiSurveyFile.targets[0].name}`,
          `<strong>Target population:</strong> ${
            multiSurveyFile.targets[0].population
              ? multiSurveyFile.targets[0].population.toLocaleString()
              : '[unknown]'
          } (${('' + (multiSurveyFile.targets[0].scale || '')).substring(1)})`
        );
      }

      multiSurveyFile.copyrightText
        ? infoDialogData.push(
            `<strong>Copyright:</strong> ${multiSurveyFile.copyrightText}`
          )
        : null;
    }

    return infoDialogData;
  }
}

// single MultiSurvey object, populated via DAU or MRF file loading
export class MultiSurvey {
  id: string;
  usedAsPlanAudience: boolean = false; // indicates if selected target from this file is used as plan audience population
  fileType: FileType;
  dau: DauFile;
  mrf: MrfFile;
  header: FileHeader;
  survey: SurveyData;
  targets: MultiSurveyTarget[];
  vehicles: MultiSurveyVehicle[];
  distribution: number[];
  copyrightText: string = '';
  copyrightMultiBaseText: string = '';
  keepThousands: boolean;

  constructor(
    survey?: SurveyData,
    targets?: MultiSurveyTarget[],
    vehicles?: MultiSurveyVehicle[]
  ) {
    this.id = uniqid();
    this.survey = survey || EMPTY_MULTI_SURVEY;
    this.targets = targets || [];
    this.vehicles = vehicles || [];
    this.header = {
      dataSource: '',
      planFilename: '',
      planExportDate: new Date(),
    };
    this.keepThousands = false;
  }

  selectTargetVehicles(targetIndex: number) {
    switch (this.fileType) {
      case FileType.DAU:
        const dauTarget = this.dau.targets[targetIndex]; // if index is -1, undefined is returned
        this.targets = dauTarget ? [dauTarget.target] : [];
        this.survey = dauTarget ? dauTarget.survey : EMPTY_MULTI_SURVEY;
        this.vehicles = dauTarget ? dauTarget.vehicles : [];
        this.distribution = dauTarget ? dauTarget.distribution : [];
        this.copyrightText = dauTarget ? dauTarget.copyrightText : '';
        this.copyrightMultiBaseText = dauTarget
          ? dauTarget.copyrightMultiBaseText
          : '';
        break;

      case FileType.MRF:
        this.targets = [{ name: this.mrf.fileData.header.campaignTitle }];
        this.survey = {
          title: this.mrf.fileData.header.dataSource,
          code: 'mrf',
          authGroup: '',
        };
        this.vehicles = this.mrf.vehicles;
        this.distribution = [];
        this.copyrightText = 'mrf copyright';
        this.copyrightMultiBaseText = 'mrf multibsed copyright';
        break;

      default:
        throw new Error(
          'MultiSurvey::selectTargetVehicles:  Unknown file format'
        );
        break;
    }
  }

  selectPlanAudience(usedAsTargetAudience: boolean) {
    switch (this.fileType) {
      case FileType.DAU:
        this.usedAsPlanAudience = usedAsTargetAudience;
        break;

      case FileType.MRF:
        return false;
      default:
        throw new Error(
          'MultiSurvey::selectPlanAudience:  Unknown file format'
        );
        break;
    }
  }

  //  format imported vehicles in a standard TargetVehicle format inserting metrics as needed
  getTargetVehicles(): TargetVehicle[] {
    const vehicles: TargetVehicle[] = [];

    switch (this.fileType) {
      case FileType.DAU:
        if (this.targets.length) {
          const target = this.targets[0];

          const vehs: TargetVehicle[] = this.vehicles.map((veh) => {
            return {
              id: `${veh.code}`,
              mnemonic: veh.code,
              mediaType: `${veh.mediaType} - ${this.header.planFilename}`,
              mediaTypeId: veh.mediaTypeId,
              addressable: false,
              calculationMethod: MULTI_SURVEY_DAU,
              isMediaGroup: false,
              title: veh.title,
              originalTitle: veh.title,
              audience: veh.audience * target.population,
              grossAudience: veh.audience * target.population,
              resps: 0, // ?
              potentialReach: 0, // ?
              compositionIndex: 0, // ?
              compositionPct: 0, // ?
              dayparts: [],
              ESG: [{ value: -1, Provider: '?' }],
              survey: EMPTY_MULTI_SURVEY,
              isMultiSurvey: true,
              multiSurveyConfig: { multiSurveyId: this.id },
            };
          });

          vehicles.push(...vehs);
        }
        break;
      case FileType.MRF:
        if (this.mrf.vehicles.length) {
          const vehs: TargetVehicle[] = this.mrf.vehicles.map((veh) => {
            return {
              id: `${MRF_PREFIX}${veh.code}`,
              mnemonic: veh.code,
              mediaType: `${veh.mediaType} - ${this.header.planFilename}`,
              mediaTypeId: veh.mediaTypeId,
              addressable: false,
              calculationMethod: MULTI_SURVEY_MRF,
              isMediaGroup: false,
              title: veh.title,
              originalTitle: veh.title,
              audience: veh.audience,
              grossAudience: veh.audience,
              resps: 0, // ?
              potentialReach: 0, // ?
              compositionIndex: 0, // ?
              compositionPct: 0, // ?
              dayparts: [],
              ESG: [{ value: -1, Provider: '?' }],
              survey: EMPTY_MULTI_SURVEY,
              isMultiSurvey: true,
              multiSurveyConfig: {
                multiSurveyId: this.id,
                vehicleRef: veh.code,
                groupRef: `mediatype|${veh.mediaType}`,
              },
            };
          });
          vehicles.push(...vehs);
        }
        break;
      default:
        throw new Error('MultiSurvey::getTargetVehicles:  Unknown file format');
    }

    return vehicles;
  }

  // format imported vehicles in a standard ScheduleVehicle format inserting metrics as needed
  getScheduleVehicles(surveyTarget: Target): ScheduleVehicle[] {
    const prefix = this.fileType === FileType.MRF ? MRF_PREFIX : '';
    const vehicles: ScheduleVehicle[] = [];

    if (this.targets.length) {
      const target = this.targets[0];
      const vehs: ScheduleVehicle[] = this.vehicles.map((veh) => {
        const result = new Result(target.population, cloneDeep(veh.result));
        if (
          this.fileType === FileType.DAU &&
          this.keepThousands &&
          result.reach > 0
        ) {
          const factor = target.scale / surveyTarget.survey.units || 1;
          result.reach =
            (result.reach * (target.population * factor)) /
            surveyTarget.population;
        }

        return {
          vehicleId: `${prefix}${veh.code}`,
          targetId: surveyTarget.id,
          isMultiSurvey: true,
          result,
        };
      });
      vehicles.push(...vehs);
    }
    return vehicles;
  }

  getFileListItemsForDialog(): DauTarget[] | MultiSurveyVehicle[] {
    switch (this.fileType) {
      case FileType.DAU:
        return this.dau.targets;
      case FileType.MRF:
        return this.mrf.vehicles;
      default:
        throw new Error('MultiSurvey::SelectTarget:  Unknown file format');
        break;
    }
  }

  getAllTargets() {
    switch (this.fileType) {
      case FileType.DAU:
        return this.dau.targets;
      case FileType.MRF:
        return [];
      default:
        throw new Error('MultiSurvey::SelectTarget:  Unknown file format');
        break;
    }
  }
}
