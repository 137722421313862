<div fxLayout="column">
  <!-- survey selection card and toolbar -->
  <survey-info-bar
    fxFlex="100"
    [allowChangeSurvey]="false"
    [showManageSurvey]="false"
    (currentSurveyChange)="onCurrentSurveyChange()"
    [currentSurvey]="surveyBarCurrentSurvey"
    [selectedSurveys]="surveyBarSelectedSurveys"
    [mediaDisabledList]="mediaDisabledList"
  >
    <media-toolbar
      [survey]="surveyBarSurvey"
      [mediaSelectorTemplate]="mediaSelector"
      [meadiaTebleSortFilter]="meadiaTebleSortFilter"
    >
    </media-toolbar>
    <ng-template #mediaSelector>
      <mat-form-field
        appearance="outline"
        tupMatFormField
        class="regular-select"
      >
        <mat-label> Display mediatype </mat-label>

        <mat-select
          (selectionChange)="onMediaTypeChange($event.value)"
          [value]="currentMediaType"
          class="select-medium"
          panelClass="dropdown-select-wrapper"
        >
          <mat-select-trigger>
            {{ currentMediaType }}
          </mat-select-trigger>

          <mat-option
            *ngFor="let mediaType of mediaTypes"
            [value]="mediaType"
            class="category-text"
          >
            <div
              fxFlex="row"
              fxLayoutAlign="space-between center"
              class="selected-option"
            >
              {{ mediaType }}
              <mat-icon *ngIf="mediaType === currentMediaType"> done </mat-icon>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </survey-info-bar>
  <div
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="24px"
  >
    <div>
      <div fxFlex="1 1 405px">
        <!-- left -->
        <!-- tree search textbox -->
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
        >
          <mat-form-field
            fxFlex
            appearance="outline"
            tupMatFormField
            class="input-form-field-search media-search"
          >
            <button
              matPrefix
              mat-icon-button
              matTooltip="Search"
              (click)="onSearchClick(treeSearch)"
              class="search-btn"
            >
              <mat-icon *ngIf="!searching">search</mat-icon>
              <processing
                *ngIf="searching"
                diameter="20"
              ></processing>
            </button>
            <input
              matInput
              type="text"
              placeholder="Search"
              [(ngModel)]="treeSearch"
              (keyup.enter)="onSearchClick(treeSearch)"
            />
            <!-- advanced search options -->
            <simple-selection
              matSuffix
              display="button"
              [options]="advancedSearchOptions.options"
              [multi]="false"
              (dropdownToggle)="onAdvancedSearchOptionChange($event)"
              buttonIcon="tune"
              matTooltip="Advanced search"
              buttonTemplateOptionsTitle="ADVANCED SEARCH"
            >
            </simple-selection>
          </mat-form-field>

          <!-- options -->
          <button
            mat-stroked-button
            tupMatButton
            appearance="tiny"
            color="primary"
            matTooltip="Reset"
            (click)="resetSearch()"
            class="reset-btn"
          >
            <mat-icon color="primary">autorenew</mat-icon>
          </button>
        </div>

        <!-- try -->
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="8px"
        >
          <div
            fxFlex="100%"
            fxLayout="column"
          >
            <!-- category dropdown -->
            <mat-form-field
              appearance="outline"
              class="select-medium"
              tupMatFormField
            >
              <mat-select
                [(ngModel)]="currentCategory"
                (ngModelChange)="onCategoryChange($event)"
                class="select-medium codebook-category"
                panelClass="dropdown-select-wrapper"
              >
                <mat-select-trigger>
                  {{ currentCategory }}
                </mat-select-trigger>

                <mat-option
                  *ngFor="let category of categories"
                  class="category-text"
                  [value]="category"
                >
                  <div
                    fxFlex="row"
                    fxLayoutAlign="space-between center"
                    class="selected-option"
                  >
                    {{ category }}
                    <mat-icon *ngIf="category === currentCategory"
                      >done</mat-icon
                    >
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- operator buttons -->
          <button
            mat-stroked-button
            tupMatButton
            *ngFor="let button of buttons"
            class="operator-btn"
            appearance="small"
            color="primary"
            [matTooltip]="button.toolTip"
            (click)="onOperationClick(button.action)"
          >
            <mat-icon>
              {{ button.label }}
            </mat-icon>
          </button>
        </div>

        <div
          class="tree-container"
          #treeContainer
        >
          <!-- tree -->
          <mat-spinner
            *ngIf="loadingMedia"
            class="spinner-middle"
          ></mat-spinner>

          <div
            *ngIf="treeData.length > 0"
            class="clear-all"
            (click)="unSelectAll()"
          >
            <span
              *ngIf="!loadingMedia"
              matTooltip="Unselect all"
            >
              Clear selection</span
            >
          </div>

          <div
            *ngIf="visible && showNoResultsAfterSearch && !loadingMedia"
            class="no-results-container"
          >
            <mat-icon
              class="material-symbols-outlined sentiment-dissatisfied-icon"
              >sentiment_dissatisfied</mat-icon
            >
            <div
              [innerHtml]="noSearchResultsMessageHTML"
              class="list-container"
            ></div>
          </div>

          <tree-table
            class="media-tree-table"
            [textOnMultipleLines]="true"
            #mediaTree
            [hidden]="loadingMedia || treeData.length === 0"
            treeWidth="auto"
            [disableDragging]="false"
            [data]="treeData"
            secondLevelCheckbox="true"
            [(selectedNodes)]="preSelectedNodes"
            (nodeDblClick)="onDoubleClick($event)"
            (expand)="OnTreeExpand($event)"
            (treeNodeMenuClick)="onTreeNodeMenuClick($event)"
          ></tree-table>
        </div>
      </div>
    </div>

    <!-- table containing selected media -->
    <div fxFlex>
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="processing"
      ></mat-progress-bar>

      <div style="padding: 0">
        <div class="media-container">
          <media-table
            #mediaTable
            [unitsText]="unitsText"
            [esgScores]="showESGColumn"
            [loadingTreeTable]="loadingVehicles"
            (deleteRows)="onMediaDeleteRows($event)"
            (dbleClick)="onMediaDbleClick($event)"
            [(dataSource)]="mediaSelection"
            (dropListDropped)="onDropCodebookItem($event)"
            (headerMenuItemClick)="onContextMenuItemClick($event)"
            [targets]="targets"
            [processing]="mediaTableProcessing"
            [mediaTypeFilter]="currentMediaType"
          ></media-table>
        </div>
      </div>
    </div>
  </div>
</div>
