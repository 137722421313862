<div class="optimise-dialog multiple-tabs-dialog">
  <tup-mat-title-bar title="Optimise">
    <span fxFlex></span>
    <button
      mat-icon-button
      tupMatButton
      appearance="tiny"
      (click)="onClose()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </tup-mat-title-bar>
  <mat-tab-group
    mat-stretch-tabs
    animationDuration="0ms"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <span data-tracking="optimise-strategy-label"> Strategy </span>
      </ng-template>
      <ng-container *ngTemplateOutlet="strategyTab"></ng-container>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span data-tracking="optimise-split-label"> Split </span>
      </ng-template>
      <ng-container *ngTemplateOutlet="reorderTab"></ng-container>
      <snackbar-generic
        class="optimise-split-values-error"
        *ngIf="!splitValueFilledCorrectly"
        [data]="snackbarError"
      >
      </snackbar-generic>
    </mat-tab>
  </mat-tab-group>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    fxLayoutGap="16px"
    class="actions-wrapper"
  >
    <button
      tupMatButton
      mat-flat-button
      data-tracking="metrics-cancel-btn"
      (click)="onClose()"
    >
      Cancel
    </button>

    <button
      mat-flat-button
      tupMatButton
      color="primary"
      data-tracking="metrics-save-btn"
      (click)="save()"
      [disabled]="
        !strategyValue ||
        strategyValue === '0' ||
        selectedMediaTypes.length <= 0 ||
        (selectedMediaTypes.length < 2 && selectedSplit != 'noSplit') ||
        (selectedSplit != 'noSplit' && splitValuesFilled == false)
      "
    >
      Optimise
    </button>
  </div>
</div>

<ng-template #strategyTab>
  <div
    class="tab-container"
    fxLayout="column"
    fxLayoutGap="8px"
  >
    <mat-form-field
      appearance="outline"
      tupMatFormField
    >
      <mat-label> Audience </mat-label>
      <input
        matInput
        disabled
        [value]="audience"
      />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      tupMatFormField
    >
      <mat-label> Plan </mat-label>

      <mat-select
        [(ngModel)]="selectedPlan"
        class="select-medium"
        disableOptionCentering
        panelClass="optimise-dropdown-select-wrapper dropdown-select-wrapper"
      >
        <mat-select-trigger>
          {{ selectedPlan }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let schedule of schedules"
          [value]="schedule.value"
        >
          <span
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            {{ schedule.name }}
            <mat-icon
              color="primary"
              *ngIf="selectedPlan == schedule.value"
              >check
            </mat-icon></span
          >
        </mat-option>
        <mat-option value="Create new plan">
          <span
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            Create new plan
            <mat-icon
              color="primary"
              *ngIf="selectedPlan == 'Create new plan'"
              >check
            </mat-icon>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div
      fxLayout="row"
      fxLayoutGap="8px"
    >
      <div
        fxFlex="50"
        fxLayout="column"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
        >
          <mat-label> Strategy </mat-label>

          <mat-select
            [(ngModel)]="selectedStrategy"
            (selectionChange)="onSelectedStrategyChange()"
            class="select-medium"
            disableOptionCentering
            panelClass="optimise-dropdown-select-wrapper strategy dropdown-select-wrapper"
          >
            <mat-select-trigger>
              {{ displayedStrategy }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let strategy of strategies"
              [value]="strategy.value"
              [disabled]="strategy.disabled"
            >
              <span
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <span
                  matTooltipPosition="right"
                  [matTooltip]="
                    strategy.tooltip && strategy.disabled
                      ? strategy.tooltip
                      : ''
                  "
                >
                  {{ strategy.name }}
                </span>
                <mat-icon
                  color="primary"
                  *ngIf="strategy.value == selectedStrategy"
                  >check
                </mat-icon>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field
          appearance="outline"
          tupMatFormField
        >
          <mat-label> Strategy value </mat-label>
          <input
            matInput
            [(ngModel)]="strategyValue"
            (blur)="onStrategyValueChange($event)"
          />
        </mat-form-field>
      </div>
    </div>

    <div
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="8px"
    >
      <div class="freq-level-label">Frequency level</div>
      <mat-slider
        class="custom-slider"
        max="8"
        min="1"
        step="1"
        tickInterval="1"
        [(ngModel)]="frequencysliderValue"
        color="primary"
      ></mat-slider>
      <div
        fxflex="100%"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="numbers-container"
      >
        <div *ngFor="let number of sliderNumbers">{{ number }}+</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #reorderTab>
  <div
    class="tab-container"
    fxLayout="column"
    fxLayoutGap="8px"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="8px"
    >
      <div
        fxFlex="26"
        fxLayout="column"
        class="constraints-label"
      >
        Constraints by media type
      </div>
      <div
        fxFlex="37"
        fxLayout="column"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
        >
          <mat-label> Split </mat-label>

          <mat-select
            [(ngModel)]="selectedSplit"
            class="select-medium"
            disableOptionCentering
            panelClass="optimise-dropdown-select-wrapper split dropdown-select-wrapper"
            (selectionChange)="onSelectedSplitChange($event)"
          >
            <mat-select-trigger>
              {{ selectedSplitName }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let split of splitValues"
              [value]="split.value"
            >
              <span
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                {{ split.name }}
                <mat-icon
                  color="primary"
                  *ngIf="split.value == selectedSplit"
                  >check
                </mat-icon>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        fxFlex="37"
        fxLayout="column"
      >
        <mat-form-field
          appearance="outline"
          tupMatFormField
        >
          <mat-label> Type </mat-label>
          <mat-select
            [(ngModel)]="selectedSplitType"
            class="select-medium"
            disableOptionCentering
            panelClass="optimise-dropdown-select-wrapper split-type dropdown-select-wrapper"
            (selectionChange)="onSelectedSplitChange($event)"
          >
            <mat-select-trigger>
              {{ selectedSplitTypeName }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let splitType of splitTypeValues"
              [value]="splitType.value"
            >
              <span
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                {{ splitType.name }}
                <mat-icon
                  color="primary"
                  *ngIf="splitType.value == selectedSplitType"
                  >check
                </mat-icon>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div>
      <tree-table
        #optimiseTable
        class="optimise-tree-table tree-table"
        treeWidth="128px"
        treeTitle="Media type"
        data-tracking="optimise-table"
        (edited)="onEdited($event)"
        [data]="optimiseData"
        [disableDragging]="true"
        [allowSelectAll]="true"
        [columns]="tableClomuns"
        (selectedNodesChange)="onSelectedNodes($event)"
      >
      </tree-table>
    </div>
  </div>
</ng-template>
