import { SurveyCompatibiltyResponse } from '../models/multi-survey-mrf.models';

export interface CompatibleStatus {
  code: string;
  message?: string;
}

export interface CompatibleStatusList {
  code: string;
  compatible: CompatibleStatus[];
  incompatible: CompatibleStatus[];
}

export class SurveyCompatibility {
  surveys: CompatibleStatusList[] = [];

  constructor() {}
  clear() {
    this.surveys = [];
  }

  /**
   * Consumes and reformats the repsonse data from the backend call: '/multisurveycompatibility'
   * @param {SurveyCompatibiltyResponse} response Response object from backend call;
   */
  update(response: SurveyCompatibiltyResponse): void {
    this.clear();
    if (!response.success || !response.results) return;

    const surveyCodes = Object.keys(response.results);
    surveyCodes.forEach((surveyCode) => {
      const survey: CompatibleStatusList = {
        code: surveyCode.toLocaleUpperCase(),
        compatible: [],
        incompatible: [],
      };

      // extract compatible list (no messages)
      if (response.results[surveyCode].compatible) {
        survey.compatible = response.results[surveyCode].compatible.map(
          (compatible) => {
            return { code: compatible.toLocaleUpperCase() };
          }
        );
      }

      // extract incompatible list (inc. message)
      if (response.results[surveyCode].incompatible) {
        const incompatibleSurveyCodes = Object.keys(
          response.results[surveyCode].incompatible
        );
        incompatibleSurveyCodes.forEach((incompatibleSurveyCode) => {
          survey.incompatible.push({
            code: incompatibleSurveyCode.toLocaleUpperCase(),
            message:
              response.results[surveyCode].incompatible[incompatibleSurveyCode],
          });
        });
      }

      this.surveys.push(survey);
    });
  }

  updateSingleSurvey(surveyCode: string): void {
    this.clear();

    const code: string = surveyCode.toLocaleUpperCase();
    this.surveys.push({
      code,
      compatible: [{ code }],
      incompatible: [],
    });
  }

  /**
   * Builds a disabled list of surveys found in the campaign from a primary survey context
   *
   * @param {string} primarySurvey Primary survey used within the campaign
   * @returns {string[]} surveys to appear disabled
   */
  mediaDisabledList(primarySurvey: string): string[] {
    const disabledList: string[] = [];

    const survey = this.surveys.find(
      (s) => s.code === primarySurvey.toLocaleUpperCase()
    );
    if (survey) {
      disabledList.push(...survey.incompatible.map((incom) => incom.code));
    }

    return disabledList;
  }
}
